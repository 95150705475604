import React from "react";
import { Card } from "./ui/card";
import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";

interface ProductCardProps {
  imageUrl: string;
  title: string;
  onClick?: () => void;
  imageClassName?: string;
  className?: string;
  href?: string;
  selected?: boolean; // Add a selected prop
}

const ProductCard: React.FC<ProductCardProps> = ({ imageUrl, title, onClick, imageClassName, className, href, selected }) => {
  return (
    <Card
      className={cn(
        "shadow-md shadow-gray-400 p-3 max-w-40 flex flex-col items-center justify-center relative rounded-lg",
        className,
        selected ? "bg-[#FFE512]" : "" // Apply background color when selected
      )}
    >
      <img
        src={imageUrl}
        alt="product"
        className={cn("object-contain h-20 my-2 mx-2", imageClassName)}
      />
      <p className="text-center text-sm mt-2 mb-3 font-black">{title}</p>
      {onClick && (
        <div className="absolute inset-0" onClick={onClick}>
          <span className="sr-only">View Product</span>
        </div>
      )}
      {href && (
        <Link className="absolute inset-0" to={href}>
          <span className="sr-only">View Product</span>
        </Link>
      )}
    </Card>
  );
};

export default ProductCard;
