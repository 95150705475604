const PricingPolicy = () => {
  return (
    <div className="my-10">
      <h1 className="font-bold ml-10">Pricing Policy</h1>
      <h4 className="mt-10 font-bold">Catalog Pricing</h4>
      <p className="mt-5 leading-6">
        All prices are current and in effect at the time of printing. <br />
        Golden Leaf Automotive reserves the right to adjust pricing and availability as needed.
        <br />
        This catalog supersedes all previous catalogs published. OEM parts numbers are for reference only.
      </p>
    </div>
  );
};

export default PricingPolicy;
