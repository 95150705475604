const WarrantyAndDamage = () => {
  return (
    <div className="my-10">
      <h1 className="font-bold ml-10">Warranty and Damage</h1>
      <h4 className="mt-10 font-bold">Damage - Warranty & Damage Information</h4>
      <p className="mt-5 leading-6">
        If, for any reason, you are not satisfied with the quality of any reproduction part purchased from us, you may
        return it for a full refund within 30 days from the date of purchase. (Invoice date) If the part fails for any
        reason (excluding chroming) you may return it to us (freight prepaid) with proof of purchase for an immediate
        replacement. This warranty supercedes any other warranty expressed or implied.
        <br />
        All packages should be inspected immediately upon delivery. The carrier should be notified as soon as possible
        if there is any notable damage. Advise our sales staff of any shipping damage if the shipment was sent DHL, you
        have 3 days to notify us of any damage incurred. If shipped by LTL carrier (truck) you have 1 day to notify us
        of any damage. As the frieght companies do not recognize hidden damage it is imperative you inspect your freight
        upon arrival. Check the large easily damaged items if nothing else. If you do not, you could be liable for any
        damage not noted on the freight bill.
      </p>
    </div>
  );
};

export default WarrantyAndDamage;
