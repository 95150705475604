import { z } from "zod";

export const ProfileSchema = z.object({
  email: z.string().email(),
  changingEmail: z.string().optional(),
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  gender: z.string().optional(),
  dob: z.string().optional(),
  address: z.string().min(2),
  city: z.string().min(2),
  postalCode: z.string().min(2),
  country: z.string().min(2),
  province: z.string().min(2),
  tel: z.string().min(2),
  mobile: z.string().optional(),
  sameAsShipping: z.boolean().optional(),
  billingAddress: z.string().min(2).optional(),
  billingCity: z.string().min(2).optional(),
  billingPostalCode: z.string().min(2).optional(),
  billingCountry: z.string().min(2).optional(),
  billingProvince: z.string().min(2).optional(),
  billingTel: z.string().min(2).optional(),
  billingMobile: z.string().min(2).optional(),
  carModel: z.string().min(2).optional(),
  carYear: z.string().or(z.number()).optional(),
  carMake: z.string().min(2).optional(),
});

export type ProfileType = z.infer<typeof ProfileSchema>;

export const submitInquirySchema = z.object({
  subject: z.string().min(2),
  message: z.string().min(20, { message: "Message must be at least 20 characters" }),
});

export type SubmitInquiryType = z.infer<typeof submitInquirySchema>;

export const changePasswordSchema = z
  .object({
    currentPassword: z.string().min(8),
    newPassword: z
      .string()
      .min(8, "Your password must be a minimum of 8 characters and include at least one number and English character.")
      .regex(/(?=.*[0-9])(?=.*[a-zA-Z])/, "Your password must be a minimum of 8 characters and include at least one number and English character."),
    confirmPassword: z.string().min(8),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"], // Add this line to specify the path of the error
  });

export type ChangePasswordType = z.infer<typeof changePasswordSchema>;
