import { z } from "zod";

export const ContactUsSchema = z.object({
  firstName: z.string().min(2).max(255),
  lastName: z.string().min(2).max(255),
  email: z.string().email(),
  message: z.string().min(20).max(255),
  subject: z.string().min(2).max(255),
});

export type ContactUsSchema = z.infer<typeof ContactUsSchema>;
