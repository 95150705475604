import { cn } from "@/lib/utils";
import { Card } from "./ui/card";
import crown from "@/assets/images/crown 1.png";
import { X } from "lucide-react";

interface SectionCardProps {
  code?: string;
  image?: string;
  title?: string;
  year?: string;
  make?: string;
  price?: string;
  promoPrice?: string;
  specialPrice?: string; // Special Price
  className?: string;
  imageClassName?: string;
  link?: string;
  showCross?: boolean;
  showCrown?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onCrossClick?: (item: string) => void;
}

const SectionCard = ({
  code,
  image,
  title,
  year,
  make,
  price,
  promoPrice,
  specialPrice, // Destructured specialPrice
  className,
  imageClassName,
  showCross,
  showCrown,
  disabled,
  onClick,
  onCrossClick,
}: SectionCardProps) => {
  return (
    <Card
      className={cn(
        "shadow-md rounded-3xl space-y-2 mb-10 mr-4 px-2 relative shadow-gray-400",
        disabled ? "opacity-50" : "cursor-pointer hover:shadow-xl shadow-gray-400",
        className
      )}
    >
      {showCrown && (
        <div className="absolute -top-5 -right-2 p-1">
          <img alt="Crown" className="w-10 h-8" src={crown} />
        </div>
      )}
      {showCross && (
        <div
          className="absolute -top-5 -right-2 p-1 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onCrossClick && onCrossClick(code || "");
          }}
        >
          <div className="w-8 h-8 bg-[#FC3333] rounded-full flex items-center justify-center">
            <X className="w-6 h-6 font-black text-white" />
          </div>
        </div>
      )}
      <div className="flex justify-center mt-2">
        <p className="text-md font-black text-[#184178]">{code || "CODE123"}</p>
      </div>
      <div className="flex justify-center">
        <img
          alt="Product Image"
          className={cn("object-contain w-60 h-60", imageClassName)}
          src={
            image ||
            "https://s3-alpha-sig.figma.com/img/f627/141c/3abe1bda4dcb8f9aa7eb320b247f789a?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=KkPFGnxd~055kjG41y4rMser7tFDJwaELFU8lURj3JY3q0FXPIz79rbu7~nIS4u9IlQJKXcZjraSv2f~0YWBzud04b54CRBfkTCAxfYRFEy4fuk363VUNFzvAtDHTepI0iVj7k5e9dFw9IN8KjApVadXdKBwliGxD-EnUr6dB-3GiNBWaVSclaIK8j2rPqG1pbiaRyMrnUJv0SMSI01qiV02b0pz4ieU7SSSfTLiDk9I8AlI1CeBsmQnFnljcXi8AhvkN2VboKLxmkqMCs6D9Q-iZZOC8mwx9iEnzrnyanypZ40DoZzSvkkLlaNAxUckx7oy4oEexjfe8ZQu0HK6xw__"
          }
          height={400}
          width={600}
        />
      </div>
      <div className="flex flex-col justify-between px-4">
        <div className="flex justify-between w-full sm:w-auto mb-2 mt-14">
          <p className="text-sm font-medium capitalize w-max">{title || "Roof Panel"}</p>
          <p className="text-sm font-medium uppercase ml-5">{make || "Cougar"}</p>
        </div>
        <div className="flex justify-between items-end w-full sm:w-auto mb-5 mt-1">
          <p className="text-sm font-medium">{year || "1967 - 1968"}</p>
          <div className="text-right">
            {specialPrice ? ( // Special Price has the highest priority
              <>
                <p className="text-lg font-black line-through">{price || "CAD 835.00"}</p>
                <p className="text-xl font-black text-red-500">{specialPrice}</p>
              </>
            ) : promoPrice ? ( // Promo Price has the second priority
              <>
                <p className="text-lg font-black line-through">{price || "CAD 835.00"}</p>
                <p className="text-xl font-black text-red-500">{promoPrice}</p>
              </>
            ) : (
              <p className="text-xl font-black">{price || "CAD 835.00"}</p> // Fallback to regular price
            )}
          </div>
        </div>
      </div>
      {onClick && (
        <div className="absolute inset-0" onClick={onClick}>
          <span className="sr-only">View Product</span>
        </div>
      )}
    </Card>
  );
};

export default SectionCard;
