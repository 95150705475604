import { Control, UseFormSetValue } from "react-hook-form";
import { CustomerInfoType } from "../types/Schema";
import SectionDivider from "@/components/sectionDivider";
import { FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { getProvincesAndStates } from "@/utils/helpers/getProvincesAndStates";
import { formatPhoneNumber, isCanadianPhoneNumber } from "@/utils/helpers/formatPhoneNumber";

interface ShippingInformationProps {
  control: Control<CustomerInfoType>;
  watchcountry: "CA" | "USA";
  setValue: UseFormSetValue<CustomerInfoType>;
}

const ShippingInformation = ({ control, watchcountry, setValue }: ShippingInformationProps) => {
  const regions = getProvincesAndStates();

  return (
    <div className="space-y-4 pt-2">
      <div className="flex justify-center">
      <SectionDivider title="Shipping Information" />
      </div>
      <FormField
        name="address"
        control={control}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input placeholder="*Address" {...field} className="border-[#BEBEBE] h-14 rounded-lg" />
            </FormControl>
            <FormMessage className="mt-1" />
          </FormItem>
        )}
      />
      <div className="grid grid-cols-2 gap-4">
        <FormField
          name="city"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="*City" {...field} className="border-[#BEBEBE] h-14 rounded-lg" />
              </FormControl>
              <FormMessage className="mt-1" />
            </FormItem>
          )}
        />
        <FormField
          name="postalCode"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="*Postal Code" {...field} className="border-[#BEBEBE] h-14 rounded-lg" />
              </FormControl>
              <FormMessage className="mt-1" />
            </FormItem>
          )}
        />
      </div>
      <div className="grid grid-cols-2 gap-3">
        <FormField
          name="country"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                    setValue("province", "");
                  }}
                  value={field.value}
                >
                  <SelectTrigger className="border-[#BEBEBE] h-14 rounded-lg">
                    <SelectValue placeholder="*Country" className="text-[#BEBEBE] h-14 rounded-lg " />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="CA">Canada</SelectItem>
                    <SelectItem value="USA">USA</SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage className="mt-1" />
            </FormItem>
          )}
        />
        <FormField
          name="province"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger className="border-[#BEBEBE] h-14 rounded-lg">
                    <SelectValue placeholder="*Province" className="text-[#BEBEBE] h-14 rounded-lg" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {(watchcountry === "CA" ? regions.canada : regions.usa).map((region) => (
                        <SelectItem key={region.value} value={region.value}>
                          {region.label}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage className="mt-1" />
            </FormItem>
          )}
        />
      </div>
      <FormField
        name="tel"
        control={control}
        rules={{ validate: (value) => isCanadianPhoneNumber(value) || "Invalid Canadian phone number" }}
        render={({ field, fieldState: { error } }) => (
          <FormItem>
            <FormControl>
              <Input
                placeholder="*Tel"
                {...field}
                className="border-[#BEBEBE] h-14 rounded-lg"
                type="tel"
                onBlur={(e) => {
                  field.onBlur();
                  e.currentTarget.value = formatPhoneNumber(e.currentTarget.value);
                }}
              />
            </FormControl>
            <FormMessage className="mt-1">{error && <span className="text-red-500">{error.message}</span>}</FormMessage>
          </FormItem>
        )}
      />
      <FormField
        name="mobile"
        control={control}
        rules={{ validate: (phoneNumber: string | undefined) => isCanadianPhoneNumber(phoneNumber || "") }}
        render={({ field, fieldState: { error } }) => (
          <FormItem>
            <FormControl>
              <Input
                placeholder="Mobile"
                {...field}
                className="border-[#BEBEBE] h-14 rounded-lg"
                type="tel"
                onBlur={(e) => {
                  field.onBlur();
                  e.currentTarget.value = formatPhoneNumber(e.currentTarget.value);
                }}
              />
            </FormControl>
            <FormMessage className="mt-1">{error && <span className="text-red-500">{error.message}</span>}</FormMessage>
          </FormItem>
        )}
      />
      <div className="h-4"></div>
    </div>
  );
};

export default ShippingInformation;
