import { Form } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { CustomerInfoType, CustomerInfoSchema } from "./types/Schema";
import { zodResolver } from "@hookform/resolvers/zod";
import PersonalInformation from "./components/personal-information";
import ShippingInformation from "./components/shipping-information";
import BillingInformation from "./components/billing-information";
import VehicalInformation from "./components/vehical-information";
import { Button } from "@/components/ui/button";
import { AppContext } from "@/context/context";
import { useContext } from "react";
import { useMutation } from "react-query";
import { addUserDetails } from "@/lib/queries";
import { isValidPostalCode } from "@/utils/helpers/isValidPostalCode";
//import toast from "react-hot-toast";
import { toast} from 'react-toastify';
import { useNavigate } from "react-router";
import { User } from "@/interfaces/user.interface";

const CustomerInfo = () => {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  const user = state.user as User;

  const form = useForm<CustomerInfoType>({
    resolver: zodResolver(CustomerInfoSchema),
    defaultValues: {
      firstName: user?.firstname || "",
      lastName: user?.lastname || "",
      gender: "",
      dob: "",
      address: "",
      city: "",
      postalCode: "",
      country: "",
      province: "",
      tel: "",
      mobile: "",
      sameAsShipping: false,
      billingAddress: "",
      billingCity: "",
      billingPostalCode: "",
      billingCountry: "",
      billingProvince: "",
      billingTel: "",
      billingMobile: "",
      carModel: "",
      carYear: "",
      carMake: "",
    },
  });

  const mutation = useMutation(addUserDetails, {
    onSuccess: (data) => {
      dispatch({ type: "SET_USER_INFO", payload: data.userinfo });
      dispatch({ type: "SET_BILLING_INFO", payload: data.billing });
      navigate("/profile");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onSubmit = (data: CustomerInfoType) => {
    const validPostCode = isValidPostalCode(data.country as "CA" | "USA", data.postalCode);
    if (!validPostCode) {
      form.setError("postalCode", { message: "Invalid postal code" });
      toast.error("Invalid postal code");
      return;
    }

    const validShippingPostCode = isValidPostalCode(
      data.billingCountry as "CA" | "USA",
      data.billingPostalCode as string
    );
    if (!watchSameAsShipping && !validShippingPostCode) {
      form.setError("billingPostalCode", { message: "Invalid billing postal code" });
      toast.error("Invalid billing postal code");
      return;
    }

    const payload = {
      data: {
        address: data.address,
        b_address1: data.billingAddress || "",
        b_address2: "",
        b_city: data.billingCity || "",
        b_country: data.billingCountry || "",
        b_phone: data.billingMobile as string,
        b_province: data.billingProvince || "",
        b_tel: data.billingTel as string,
        b_zipcode: data.billingPostalCode || "",
        brithday: data.dob as string,
        car: data.carModel as string,
        city: data.city as string,
        country: data.country as string,
        forename: data.firstName,
        gender: data.gender as string,
        make: data.carMake as string,
        mobile: data.mobile as string,
        sameAsBilling: data.sameAsShipping as boolean,
        state: data.province as string,
        surname: data.lastName,
        tel: data.tel as string,
        year: parseInt(data.carYear as string),
        zipcode: data.postalCode as string,
      },
      userID: state.user?.id as number,
    };
    mutation.mutate(payload);
  };

  const watchSameAsShipping = form.watch("sameAsShipping") as boolean;
  const watchcountry = form.watch("country") as "CA" | "USA";
  const watchbillingCountry = form.watch("billingCountry") as "CA" | "USA";

  return (
    <div className="container mx-auto mt-44 mb-20 px-44">
      <h1 className="font-bold text-3xl text-center">Almost there...</h1>
      <p className="text-center font-light mt-5 text-[#3D3D3D]">
        Tell us a little bit more to let us serve you better.
        <br />
        <span className="text-red-500">* </span>Remember fill out all required entry fields.
      </p>

      <div className="flex items-center gap-2 flex-col mt-5 w-3/4 lg:w-5/12 m-auto">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-3">
            <PersonalInformation control={form.control} />
            <ShippingInformation control={form.control} watchcountry={watchcountry} setValue={form.setValue} />
            <BillingInformation
              control={form.control}
              watchSameAsShipping={watchSameAsShipping}
              watchbillingCountry={watchbillingCountry}
              setValue={form.setValue}
            />
            <VehicalInformation control={form.control} />
            <div className="flex justify-center">
              <Button variant={"outline"} type="submit" className=" text-lg w-[56%] h-16 rounded-lg border-[#3D3D3D] mt-1">
                Register and join now
              </Button>
            </div>
            <p className="italic text-sm text-center w-[60%] mx-auto">
              By registering you agree to the terms and conditions of <br></br> Golden Leaf Automotive website
            </p>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default CustomerInfo;
