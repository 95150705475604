const ShippingAndDelivery = () => {
  return (
    <div className="my-10">
      <h1 className="font-bold ml-10">Shipping and Delivery</h1>
      <h4 className="mt-10 font-bold">Domestic Shipping Charges</h4>
      <p className="mt-5">GLA follows the following shipping procedures.</p>
      <p className="my-5">
        <strong>BASIC SHIPPING: </strong>DHL Groundtrack Delivery Service/Sameday Worldwide
      </p>
      <p className="my-5 leading-6">
        <strong>PREMIUM SHIPPING OFFERED: </strong>Next Day (Air Service)
        <br />
        GLA Warehouse Trucking Companies used:
        <br />
        Sameday RightOWay, Vitran, Fastfreight. <br /> Other companies used at your request. When requesting another
        freight line please have all information available
      </p>
      <p className="my-5 leading-6">
        <strong>Optional shipping methods:</strong>
        <br />
        UPS/FedEx/DHL 3 Day Guaranteed Ground, 2nd Day Air, Next Day Air, and parcel post shipping is available at
        actual cost. Rates vary according to weight.
      </p>
    </div>
  );
};

export default ShippingAndDelivery;
