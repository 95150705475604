import { isCanadianPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { z } from "zod";

export const CustomerInfoSchema = z.object({
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  gender: z.string().optional(),
  dob: z.string().optional(),
  address: z.string().min(2),
  city: z.string().min(2),
  postalCode: z.string().min(2),
  country: z.string().min(2),
  province: z.string().min(2),
  tel: z
    .string()
    .min(2)
    .refine((value) => isCanadianPhoneNumber(value), {
      message: "Invalid phone number",
    }),
  mobile: z.string().optional(),
  sameAsShipping: z.boolean().optional(),
  billingAddress: z.string().optional(),
  billingCity: z.string().optional(),
  billingPostalCode: z.string().optional(),
  billingCountry: z.string().optional(),
  billingProvince: z.string().optional(),
  billingTel: z.string().optional(),
  billingMobile: z.string().optional(),
  carModel: z.string().optional(),
  carYear: z.string().optional(),
  carMake: z.string().optional(),
});

export type CustomerInfoType = z.infer<typeof CustomerInfoSchema>;
