import { FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Checkbox } from "@/components/ui/checkbox";
import { Control } from "react-hook-form";
import { ConfirmOrder } from "../types/Schema";

interface OrderSummaryProps {
  control: Control<ConfirmOrder>;
  subtotal: number;
  shipping: number;
  tax: number;
  total: number;
}

const OrderSummary = ({ control, subtotal, shipping, tax, total }: OrderSummaryProps) => {
  return (
    <div>
      <div className="bg-[#3D3D3D] text-white rounded-[31px] h-10 flex justify-center items-center">
        <h2 className="text-lg font-bold">Order Summary</h2>
      </div>
      <div className="p-4 mx-4 space-y-2 shadow-md shadow-gray-400">
        <div className="flex justify-between">
          <span className="text-gray-600">Subtotal:</span>
          <span className="font-black text-gray-900">$ {subtotal.toFixed(2)} CAD</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">Shipping:</span>
          <span className="font-black text-gray-900">$ {shipping.toFixed(2)} CAD</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">Tax:</span>
          <span className="font-black text-gray-900">$ {tax.toFixed(2)} CAD</span>
        </div>
        <div className="border-[#3D3D3D] my-4 border-t-2"></div>
        <div className="flex justify-between">
          <span className="text-lg text-gray-900">Total:</span>
          <span className="text-lg font-black text-gray-900">$ {total.toFixed(2)} CAD</span>
        </div>
      </div>
      <div className="flex justify-center">
        <FormField
          name="storePickup"
          control={control}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-2 space-y-0 mt-5">
              <FormControl className="h-5 w-5">
                <Checkbox checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel className="text-lg font-black">In store pickup</FormLabel>
              </div>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default OrderSummary;
