import { PRIVACY_POLICY, PrivacyPolicy } from "@/utils/constants";
import { Menu, X } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router";
import MobileNav from "./mobileNav";

interface SidebarProps {
  activeTab: string;
  setActiveTab: (tab: PrivacyPolicy) => void;
}

const Sidebar = ({ activeTab, setActiveTab }: SidebarProps) => {
  const navigate = useNavigate();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <div className="bg-[#ECECEC] md:min-h-[calc(100vh-64px)] h-full flex md:justify-end sticky top-0 px-9 md:px-0 z-10">
      <div className="flex flex-col md:mt-28 my-5">
        <ul className="mr-5 space-y-2 hidden md:flex md:flex-col">
          {PRIVACY_POLICY.map((tab, index) => (
            <li key={index} className="cursor-pointer" onClick={() => setActiveTab(tab)}>
              <div className={`text-end hover:text-[#E5B80B] ${activeTab === tab ? "text-[#E5B80B] font-bold" : ""}`}>
                {tab}
              </div>
            </li>
          ))}
          <li className="cursor-pointer" onClick={() => navigate("/")}>
            <div className={`text-end hover:text-[#E5B80B] `}>Return to Home</div>
          </li>
        </ul>
        <button
          className="flex items-center space-x-2 ml-2 md:hidden gap-4"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          {showMobileMenu ? <X /> : <Menu />} {activeTab}
        </button>

        {showMobileMenu && (
          <MobileNav
            setCloseMenu={(bool) => setShowMobileMenu(bool)}
            tabs={PRIVACY_POLICY}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          ></MobileNav>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
