import { Form } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { ConfirmOrderDealer, ConfirmOrderDealerSchema } from "./types/Schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router";
import OrderTable from "./components/OrderTable";
import ShippingForm from "./components/ShippingForm";
import OrderSummary from "./components/OrderSummary";
import { useQuery } from "react-query";
import { QueryKeys, getCheckoutItemsDealer, getTaxRate, dealerConfirm  } from "@/lib/queries";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/context";
import Loader from "@/components/Loader";

const ConfirmOrderDealerPage = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [taxRate, setTaxRate] = useState(0);

  const { data, isLoading } = useQuery(QueryKeys.CART, () => getCheckoutItemsDealer(state.user?.id as number), {
    enabled: !!state.user,
    refetchOnWindowFocus: false,
  });

  const form = useForm<ConfirmOrderDealer>({
    resolver: zodResolver(ConfirmOrderDealerSchema),
    defaultValues: {
      company: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      tel: "",
      notes: "",
    },

  });

  const { setValue, watch, control, reset } = form;
  const watchProvince = watch("province");
  const watchCountry = watch("country");

  const mapCountryToCode = (country: string) => {
    switch (country) {
      case "CANADA":
        return "CA";
      case "UNITED STATES":
        return "USA";
      default:
        return country;
    }
  };

  const updateTaxRate = async (province: string) => {
    const taxData = await getTaxRate(province);
    setTaxRate(taxData.taxRate);
  };

  useEffect(() => {
    const fetchTaxRate = async (province: string) => {
      await updateTaxRate(province);
    };

    if (data?.dealerInfo) {
      const province = data.dealerInfo.terr;
      const countryCode = mapCountryToCode(data.dealerInfo.country);
      setValue("company", data.dealerInfo.company);
      setValue("address", data.dealerInfo.address1);
      setValue("city", data.dealerInfo.city);
      setValue("province", province);
      setValue("postalCode", data.dealerInfo.zip);
      setValue("country", countryCode);
      setValue("tel", data.dealerInfo.phone);
      setValue("notes", "");
      fetchTaxRate(province);
    }
  }, [data, setValue]);

  useEffect(() => {
    if (watchProvince) {
      updateTaxRate(watchProvince);
    }
  }, [watchProvince]);

  useEffect(() => {
    const updateProvince = (country: string) => {
      if (country === "CA") {
        setValue("province", "ON");
      } else if (country === "USA") {
        setValue("province", "NY");
      }
    };

    updateProvince(watchCountry);
  }, [watchCountry, setValue]);

  const onSubmit = async (formData: ConfirmOrderDealer) => {
    console.log("clicked");
    const dealerConfirmData = {
      address: {
        company: formData.company,
        address: formData.address,
        city: formData.city,
        state: formData.province,
        zipcode: formData.postalCode,
        country: formData.country,
        tel: formData.tel,
      },
      dealerId: state.user?.id || 0,
      note: formData.notes,
    };

    try {
      const confirmResponse = await dealerConfirm(dealerConfirmData);

      if (confirmResponse?.status === "ok") {
        dispatch({
          type: "SET_ORDER",
          payload: {
            sono: confirmResponse.sono,
          },
        });

        // Navigate to the dealer-order-receipt page with the sono as a query parameter
        navigate(`/dealer-order-receipt?so=${confirmResponse.sono}&id=${data?.dealerInfo.custno}`);
      } else {
        console.error("Order confirmation failed:", confirmResponse?.status);
      }
    } catch (error) {
      console.error("Error during order confirmation:", error);
    }
  };

  const cartItems = data?.carts.map((item: any) => ({
    description: item.descrip,
    code: item.item,
    make: item.make,
    quantity: item.qty,
    price: item.price,
  })) || [];

  const watchcountry = form.watch("country") as "CA" | "USA";

  const subtotal = data?.subtotal || 0;
  const tax = subtotal * taxRate;
  const shipping = 0;
  const total = subtotal + shipping + tax;

  const requiredFieldsLoaded = !!watch("province");

  if (isLoading || !requiredFieldsLoaded) {
    return <Loader />;
  }

  const handleClear = () => {
    const currentCountry = watch("country");
    const currentProvince = watch("province");
  
    reset({
      company: "",
      address: "",
      city: "",
      postalCode: "",
      tel: "",
      notes: "",
      country: currentCountry,
      province: currentProvince,
    });
  };

  return (
    <div className="container mx-auto my-44">
      <h1 className="text-center font-black text-[#3D3D3D] text-3xl my-5">Confirm Order</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <div className="col-span-2 space-y-5">
              <OrderTable items={cartItems} />

              <div className="space-y-5">
                <ShippingForm control={control} watchcountry={watchcountry} setValue={form.setValue} />

                <div className="flex gap-11 mt-4">
                  <Button
                    className="border-2 border-[#184178] h-12 rounded-2xl text-md text-[#184178] font-black w-[22%] shadow-md bg-white"
                    type="button"
                    onClick={handleClear}
                    variant={"outline"}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-span-1">
              <OrderSummary
                control={control}
                subtotal={subtotal}
                shipping={0}
                tax={tax}
                total={total}
              />
              <div className="mt-16 flex justify-center flex-col items-center gap-3">
                <Button
                  className="bg-[#184178] h-12 hover:bg-[#6A9EE6] rounded-2xl text-md text-white font-black w-4/5 shadow-md"
                  type="submit"
                >
                  Confirm Order
                </Button>
                <Button
                  className="border-2 border-[#184178] h-12 rounded-2xl text-md text-[#184178] font-black w-4/5 shadow-md"
                  onClick={() => navigate("/cart")}
                  type="button"
                  variant={"outline"}
                >
                  Edit Order
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default ConfirmOrderDealerPage;
