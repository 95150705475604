import { CANADA_PROVINCES, USA_STATES } from "../constants";

/**
 * Retrieves an array of provinces and states for Canada and the USA.
 *
 * @returns An object containing arrays of provinces and states for Canada and the USA.
 */
export function getProvincesAndStates(): {
  canada: { label: string; value: string }[];
  usa: { label: string; value: string }[];
} {
  return {
    canada: CANADA_PROVINCES,
    usa: USA_STATES,
  };
}
