import BannerImage from "@/assets/images/ccbd_header.jpg";

const Banner = () => {
  return (
    <div className="relative w-screen left-0 mt-44" style={{ marginLeft: 'calc(-50vw + 50%)' }}>
      <img src={BannerImage} alt="Banner" className="w-full h-auto object-cover" />
    </div>
  );
};

export default Banner;