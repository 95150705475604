import ProductCard from "@/components/productCard";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { IMAGE_URL } from "@/config/appConfig";
import { QueryKeys, fetchMakes } from "@/lib/queries";
import { getYearList } from "@/utils/helpers/getYearList";
import { ArrowDown, ArrowUp, X } from "lucide-react"; // Import X icon for clear button
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";

interface FilterProductProps {
  open?: boolean;
  make?: string;
  year?: number;
  item?: string; // Add item here
  handleMakeChange?: (make: string) => void;
  handleYearChange?: (year: number) => void;
  handleSearch?: (year: number, item: string) => void; // New handler for search
}

const FilterProduct = ({
  open: defaultOpen,
  make,
  year,
  item,
  handleMakeChange,
  handleYearChange,
  handleSearch
}: FilterProductProps) => {
  const [open, setOpen] = useState(defaultOpen || false);
  const [localItem, setLocalItem] = useState<string>(item || ""); // Use item prop
  const [selectedMake, setSelectedMake] = useState<string>(make || ""); // Track selected make
  const node = useRef<HTMLDivElement | null>(null);
  const filterByYearAndMakeRef = useRef<HTMLDivElement | null>(null);

  const { data: makes } = useQuery(QueryKeys.MAKES, () => fetchMakes(), {
    refetchOnWindowFocus: false,
  });

  const yearList = getYearList(1949);
  const navigate = useNavigate();

  const handleClick = (make: string) => {
    navigate(`/all-products/${make}`);
    setSelectedMake(make); // Update selected make
    setOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalItem(e.target.value);
  };

  const handleClearClick = () => {
    setLocalItem(""); // Clear the input
  };

  const handleSearchClick = () => {
    if (handleSearch) {
      handleSearch(year || 0, localItem); // Use year and localItem here
    }
    setOpen(false); // Close filter after search
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && handleSearch) {
      handleSearch(year || 0, localItem); // Use year and localItem here
      setOpen(false); // Close filter after pressing Enter
    }
  };

  return (
    <div
      className="cursor-pointer border-t bg-white shadow-md shadow-gray-400 rounded-md px-3 md:px-6 h-10 flex items-center my-5 relative z-20 w-[90%] md:w-[72%] mx-auto"
      onClick={() => setOpen(!open)}
      ref={node}
    >
      <p className="flex items-center gap-1 mx-auto text-sm md:text-base">
        Filter {open ? <ArrowUp className="h-4 w-4" /> : <ArrowDown className="h-4 w-4" />}
      </p>
      {open && (
        <div
          className="absolute z-20 w-full mt-2 left-0 right-0 rounded-b-md shadow-lg shadow-gray-400 bg-white space-y-2 py-2 top-0"
          onClick={(event) => event.stopPropagation()}
        >
          <p className="text-center mt-3 font-bold text-sm md:text-base">Filter By</p>
          
          {/* Responsive Flex Layout */}
          <div className="flex flex-col gap-4 px-4 sm:flex-row sm:justify-center sm:items-center pt-2" ref={filterByYearAndMakeRef}>
            
            {/* Select Year */}
            <Select
              onValueChange={(value) => {
                if (handleYearChange) {
                  handleYearChange(parseInt(value));
                }
                setOpen(false); // Close filter after selecting year
              }}
              value={year ? year.toString() : "0"} // Set selected value
            >
              <SelectTrigger className="w-full sm:w-[120px] border-black h-8">
                <SelectValue placeholder="Year" className="text-[#BEBEBE]" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="0">All</SelectItem>
                  {yearList.map((year, index) => (
                    <SelectItem key={index} value={year.toString()}>
                      {year}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
  
            {/* Input for item */}
            <div className="flex items-center border border-black h-8 px-2 rounded-md w-full sm:w-auto">
              <input
                type="text"
                placeholder="Item"
                value={localItem} // Set input value
                onChange={handleInputChange}
                onKeyDown={handleKeyPress} // Handle Enter key press
                className="border-none flex-grow text-sm"
              />
              <button onClick={handleClearClick} className="text-gray-500 ml-2">
                <X className="h-5 w-5" />
              </button>
            </div>
  
            {/* Search Button */}
            <Button
              type="submit"
              className="bg-[#FFE512] h-8 w-full sm:w-28 hover:bg-[#FFE512] text-[#3D3D3D] font-black"
              onClick={handleSearchClick} // Handle button click
            >
              Search
            </Button>
          </div>
  
          {/* Makes List with Responsive Grid Layout */}
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-7 gap-4 px-4 md:px-8 pt-5 max-h-[300px] md:max-h-[50%] overflow-y-auto no-scrollbar pb-2">
            {makes &&
              makes.length > 0 &&
              makes.map((makeItem, index) => (
                <ProductCard
                  key={index}
                  imageUrl={IMAGE_URL + makeItem.path}
                  title={makeItem.make && makeItem.make.replace(/_/g, " ").toUpperCase()}
                  onClick={() => {
                    if (handleMakeChange) {
                      handleMakeChange(makeItem.make);
                    } else {
                      handleClick(makeItem.make);
                    }
                    setOpen(false);
                  }}
                  imageClassName={makeItem.make === selectedMake ? "border border-[#FFE512] rounded-md" : ""} // Highlight selected make
                />
              ))}
          </div>
          {/* Close Icon */}
          <div className="flex items-center " onClick={() => setOpen(false)}>
            <ArrowUp className="h-5 w-5 mx-auto" />
          </div>
        </div>
      )}
    </div>
  );
  
};

export default FilterProduct;
