import ModelDetailPage from "@/pages/classicBody/modelDetailPage";
import { Dialog, DialogContent } from "../ui/dialog";

interface CatalogDetailDialogProps {
  open: boolean;
  onClose: () => void;
  productCode: string;
}

const CatalogDetailDialog = ({ open, onClose, productCode }: CatalogDetailDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        className="sm:max-w-5xl max-h-[calc(100vh-64px)] overflow-y-auto no-scrollbar"
        overlayClassName="bg-neutral-600/50"
      >
        <ModelDetailPage modelCode={productCode} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default CatalogDetailDialog;
