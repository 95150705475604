import { isCanadianPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { z } from "zod";

export const ConfirmOrderDealerSchema = z.object({
  company: z.string().min(2),
  address: z.string().min(2),
  city: z.string().min(2),
  postalCode: z.string().min(2),
  country: z.string().min(2),
  province: z.string().min(2),
  tel: z
  .string()
  .min(2)
  .refine((value) => isCanadianPhoneNumber(value), {
    message: "Invalid phone number",
  }),
  notes: z.string(),
});

export type ConfirmOrderDealer = z.infer<typeof ConfirmOrderDealerSchema>;
