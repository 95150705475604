import { API_URL } from "@/config/appConfig";
import { Catalog } from "@/interfaces/catalog.interface";
import { Dealer, DealerInfo } from "@/interfaces/dealer.interface";
import { Make } from "@/interfaces/make.interface";
import { ClassicBodyModel, ClassicBodyModelItem, Product, OneOrderProduct, ItemMakes } from "@/interfaces/product.interface";
import { BillingInfo, User, UserInfo, OneOrderAddress, OneOrderSomast, Somast } from "@/interfaces/user.interface";

export enum QueryKeys {
  RANDOM_PRODUCTS = "random-products",
  HOT_SALE_PRODUCTS = "hot-sale-products",
  PROMOTIONAL_PRODUCTS = "promotional-products",
  MAKES = "makes",
  USER = "user",
  ORDER_HISTORY = "order-history",
  VIEWED_PRODUCTS = "viewed-products",
  ITEM = "item",
  WISHLIST = "wishlist",
  CART = "cart",
  PRODUCT_LIST = "product-list",
  CLASSIC_MODELS = "classic-models",
  CLASSIC_MODEL_DETAIL = "classic-model-detail",
  CATALOG = "catalogs",
  ITEMS_QUANTITY = "items-quantity",
  SPECIAL = "special",
  PROMOTIONS = "promotions",
  SEARCH_RESULTS = "search-results",
  ORDER_RECEIPT = "order-receipt",
  ONE_ORDER = "one-order",
  MODELS = "models",
}

async function fetchData<T>(url: string): Promise<T> {
  return (
    await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        credentials: "include",
      },
    })
  ).json();
}

async function postData<T>(url: string, data: any): Promise<T> {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

interface SignInData {
  email: string;
  password: string;
}

type SignInResponse =
  | {
      user: User;
      userInfo: UserInfo | null;
      billing: BillingInfo | null;
    }
  | [];

export const signIn = async (data: SignInData) => await postData<SignInResponse>(`${API_URL}/loginCustomer`, data);

export const resetPassword = async (email: string) =>
  await postData<{ status: "good" | "fail" }>(`${API_URL}/resetPassword`, { email });

type DealerLoginResponse = {
  dealer: Dealer | string;
  dealerInfo: DealerInfo;
};

interface DealerLoginData {
  account: string;
  password: string;
}

export const dealerLogin = async (data: DealerLoginData) =>
  await postData<DealerLoginResponse>(`${API_URL}/loginDealer`, data);

export const fetchDealerInfo = async (dealer: string) =>
  await fetchData<{ dealerInfo: DealerInfo }>(`${API_URL}/dealerInfo/${dealer}`);

export const fetchUserInfo = async (user: number) =>
  await postData<{ userInfo: UserInfo }>(`${API_URL}/userDetails`, { userID: user });

interface OrderHistory {
  id: number;
  order_num: string;
  account: string;
  subtotal: number;
  tax: number;
  shipping: number;
  date_order: string; // Use string type for date to represent the ISO 8601 format
  sales_status: number;
  courier: string;
  track_num: string;
  notes: string | null; // Allow null for optional fields
  address: string;
  quoteNote: string | null;
}

export const dealerOrderHistory = async (dealer: string) =>
  await fetchData<{ history: OrderHistory[]; pending: OrderHistory[] }>(
    `${API_URL}/dealerOrderHistory?account=${dealer}`
  );

interface DownloadPriceSheetResponse {
  status: "success" | "fail";
}

interface ModelListResponse {
  model_list: string[];
  old_file: boolean;
  need_file: boolean;
}

export const downloadPriceSheet = async (level: string, make: string) =>
  await fetchData<DownloadPriceSheetResponse>(`${API_URL}/generatePriceSheet/${level}/${make}`);

export const fetchModelList = async (level: string) =>
  await fetchData<ModelListResponse>(`${API_URL}/getModelList/${level}`);


interface SignUpData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  checked: boolean;
}

interface SignUpResponse {
  user: User;
  userInfo: UserInfo;
}

export const signUp = async (data: SignUpData) => await postData<SignUpResponse>(`${API_URL}/newCustomer`, data);

type UserDetailData = {
  sameAsBilling: boolean;
  surname: string;
  forename: string;
  gender: string;
  brithday: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  tel: string;
  b_tel: string;
  mobile: string;
  car: string;
  year: number;
  make: string;
  b_address1: string;
  b_address2: string;
  b_city: string;
  b_province: string;
  b_country: string;
  b_phone: string;
  b_zipcode: string;
};

interface PayloadData {
  data: UserDetailData;
  userID: number;
}

interface UserDetailResponse {
  userinfo: UserInfo;
  billing: BillingInfo;
}

export const addUserDetails = async (data: PayloadData) =>
  await postData<UserDetailResponse>(`${API_URL}/userDetails`, data);

export const updateUserDetails = async (data: PayloadData) =>
  await postData<UserDetailResponse>(`${API_URL}/updateUserInfo`, data);

interface RandomProductsResponse {
  random_make: string;
  random_items: Product[];
}

export const fetchRandomProducts = async () =>
  await fetchData<RandomProductsResponse>(`${API_URL}/getRandomNewProducts`);

export const fetchHotSaleProducts = async () => await fetchData<Product[]>(`${API_URL}/popular`);

export const promotionalProducts = async () => await fetchData<Product[]>(`${API_URL}/featureProducts`);

export const fetchMakes = async () => await fetchData<Make[]>(`${API_URL}/makes`);

interface SpecialProductResponse {
  current_page: number;
  data: Product[];
  from: number | null;
  last_page: number;
  next_page_url: string | null;
  per_page: number;
  prev_page_url: string | null;
  to: number | null;
  total: number;
  path: string;
}

export const fetchSpecialsProducts = async (data: { page: number }) =>
  await postData<{ special: SpecialProductResponse }>(`${API_URL}/searchHolidaySale`, data);

interface HolidayProduct {
  id: number;
  item: string;
  org_price: number;
  sale_price: number;
}
interface ApiResponse {
  item: HolidayProduct[];
}

export const specialsProducts = async (): Promise<ApiResponse> => {
  const response = await fetchData<ApiResponse>(`${API_URL}/holidayItem`);
  return response;
};


export const fetchPromotionsProducts = async (page?: number, make?: string, year?: number, item?: string) => {
  let queryParams = new URLSearchParams();

  if (page !== undefined) queryParams.append("page", page.toString());
  if (make) queryParams.append("make", make);
  if (year !== undefined) queryParams.append("year", year.toString());
  if (item) queryParams.append("item", item);

  const queryString = queryParams.toString();
  return await fetchData<{ items: SpecialProductResponse }>(
    `${API_URL}/searchFeatured${queryString ? `?${queryString}` : ""}`
  );
};

export const fetchSearchResults = async (make?: string, year?: number, item?: string, page?: number, desc?: string) => {
  let queryParams = new URLSearchParams();
  if (make) queryParams.append("make", make);
  if (page) queryParams.append("page", page.toString());
  if (year) queryParams.append("year", year.toString());
  if (item) queryParams.append("item", item);
  if (desc) queryParams.append("desc", desc);
  const queryString = queryParams.toString();
  return await fetchData<{ items: SpecialProductResponse }>(
    `${API_URL}/searchResualt${queryString ? `?${queryString}` : ""}`
  );
};

interface ProductListResponse {
  current_page: number;
  data: Product[];
  first_page_url: string;
  from: number | null;
  last_page: number;
  last_page_url: string;
  links: {
    url: string | null;
    label: string;
    active: boolean;
  }[];
  next_page_url: string | null;
  path: string;
  per_page: number;
  prev_page_url: string | null;
  to: number | null;
  total: number;
}

export const fetchProductList = async (make: string) =>
  await fetchData<ProductListResponse>(`${API_URL}/product_list/${make}/1`);

interface CustomerOrderHistoryResponse {
  pending: any[];
  history: any[];
}

export const getCustomerOrderHistory = async (userID: number) =>
  await fetchData<CustomerOrderHistoryResponse>(`${API_URL}/customerOrderHistory?id=${userID}`);

export const viewedProducts = (item: string) => postData(`${API_URL}/viewed`, { item: item });

export const fetchItem = async (item: string) => await fetchData<{ singleItem: Product, item_makes: ItemMakes[] }>(`${API_URL}/item/${item}`);

interface WishlistResponse {
  status: "saved" | "exist";
}

export const addItemToWishlist = async (data: { item: string; user: number }) =>
  postData<WishlistResponse>(`${API_URL}/addToWishlist`, data);

export const addItemsToWishlistDealer = async (data: { item: string; user: number }) =>
  postData<WishlistResponse>(`${API_URL}/addToWishlist_dealer`, data);

export const fetchWishlistItems = (userId: number) =>
  fetchData<{ items: Product[] }>(`${API_URL}/wishlist?userid=${userId}`);

export const fetchDealerWishlistItems = (userId: number) =>
  fetchData<{ items: Product[] }>(`${API_URL}/wishlist_dealer?userid=${userId}`);

type response = 1 | 0 | -1;

export const removeItemFromWishlist = async (data: { item: string; user: number }) =>
  postData<response>(`${API_URL}/removeFromWishlist`, data);

export const removeItemFromWishlistDealer = async (data: { item: string; user: number }) =>
  postData<response>(`${API_URL}/removeFromWishlist_dealer`, data);

interface addItemsToCartResponse {
  status: "saved" | "exist";
}

export const addItemsToCart = async (data: { item: string; user: number; quantity: number; type: number }) =>
  postData<addItemsToCartResponse>(`${API_URL}/addToShoppingCart`, data);

export const getItemsInCart = async (data: { user: number; type: number}) =>
  await postData<{ carts: Product[] }>(`${API_URL}/getItems_carts`, data);

type Order = {
  id: number;
  cust_id: number;
  item: string;
  quantity: number;
  date: string;
};

export const getCurrentItemsQuantity = (data: { user: number; type: number}) =>
  postData<{ quantity: Order[] }>(`${API_URL}/getCurrentQuantity`, data);

interface UpdateQuantityData {
  item: string;
  quantity: string | number;
  type: number;
  user: number;
}

interface UpdateQuantityResponse {
  status: "updated" | "fail";
}

export const updateQuantity = (data: UpdateQuantityData) =>
  postData<UpdateQuantityResponse>(`${API_URL}/updateQuantity`, data);

export const checkout = (userID: number) => postData(`${API_URL}/checkout`, { userID });

export const checkoutDealer = (userID: number) => postData(`${API_URL}/checkoutDealer`, { userID });

// Define the expected response shape for checkout items
interface CheckoutItemsResponse {
  userInfo: UserInfo;
  carts: Product[];
  subtotal: number;
  tax_total: number;
  shippingRate: string;
  total_shipping: number;
  takedays: Record<string, string>;
  addressBook: AddressBook[]; // Replace with a more specific type if available
  oversize: boolean;
  isOffHours: boolean;
}

export const getCheckoutItems = async (userID: number): Promise<CheckoutItemsResponse> =>
  await fetchData<CheckoutItemsResponse>(`${API_URL}/shortlist?userid=${userID}`);

//Dealer Checkout Process
interface CheckoutItemDealerResponse {
  dealerInfo: DealerInfo;
  carts: Product[];
  subtotal: number;
  tax_total: number;
  addressBook: any[];
}

export interface AddressBookDealerReceive {
  address: string;
  city: string;
  company: string;
  country: string;
  id: number;
  order_num: string;
  postalcode: string;
  province: string;
  tel: string;
}

interface AddressBookDealerSend {
  address: string;
  city: string
  company: string;
  country: string;
  state: string;
  tel: string;
  zipcode: string;
}

export const getCheckoutItemsDealer = async (userID: number): Promise<CheckoutItemDealerResponse> =>
  await fetchData<CheckoutItemDealerResponse>(`${API_URL}/DealerShortlist?userid=${userID}`);

interface DealerConfirm {
  address: AddressBookDealerSend;
  dealerId: number;
  note: string;
}

interface DealerConfirmResponse{
  sono: string;
  status: string;
}

export const dealerConfirm = async (data: DealerConfirm) =>
  await postData<DealerConfirmResponse>(`${API_URL}/dealerConfirm`, data);

export const getClassicModels = () => fetchData<{ models: ClassicBodyModel[] }>(`${API_URL}/getClassicModels`);

type Info = {
  id: number;
  item: string;
  model: string;
  make: string;
  year_model: string;
  includes: string;
  shipping: string;
};

type ImgPath = {
  id: number;
  item: string;
  path: string;
  main: number;
};

interface ClassicModelDetailResponse {
  singleItem: ClassicBodyModelItem;
  info: Info[];
  img_path: ImgPath[];
}

export const getClassicModelDetail = (code: string) =>
  fetchData<ClassicModelDetailResponse>(`${API_URL}/classicBody/${code}`);

interface InquiryData {
  custno: number | string;
  message: string;
  subject: string;
  type: "customer" | "dealer";
  user: string;
}

interface InquiryResponse {
  status: boolean;
}

export const submitInquiry = async (data: InquiryData) => await postData<InquiryResponse>(`${API_URL}/inquiry`, data);

type ChangePasswordData = {
  oldPass: string;
  newPass: string;
  confirmPass: string;
};

interface ChangePassword {
  data: ChangePasswordData;
  userID: number;
}

interface ChangePasswordResponse {
  status: "OK" | "fail";
}

export const changePassword = async (data: ChangePassword) =>
  await postData<ChangePasswordResponse>(`${API_URL}/changePassword`, data);

export const fetchCatalogs = () => fetchData<{ catalogs: Catalog[] }>(`${API_URL}/catalogs`);

interface ContactUsData {
  email: string;
  message: string;
  name: string;
  subject: string;
}

export const contactUs = async (data: ContactUsData) =>
  await postData<InquiryResponse>(`${API_URL}/inquiryOnline`, data);

export const getTaxRate = async (province: string) => {
  const response = await fetch(`${API_URL}/taxRate/${province}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const getOrderReceipt = async (userID: number): Promise<CheckoutItemsResponse> =>
  await fetchData<CheckoutItemsResponse>(`${API_URL}/shortlist?userid=${userID}`);


//getQuote function
export const getQuote = async (payload: { id: number; addressID: number; subtotal: string; hst: string; notes: string }) =>
  await postData<{ status: string; message: string; sono:number}>(`${API_URL}/getQuote`, payload);


// Define the expected response shape for one order
export interface OneOrderResponse {
    address: OneOrderAddress;
    somast: OneOrderSomast;
    oneOrder: OneOrderProduct[];
}


export const getOneOrder = async (so: number, userID: number): Promise<OneOrderResponse> => 
  await fetchData<OneOrderResponse>(`${API_URL}/oneOrder?so=${so}&id=${userID}`);



// PayPal payment data interface
interface PayPalPaymentData {
  custno: number;
  addressID: number;
  subtotal: string;
  hst: number;
  shipping: number;
  shippingDays: number;
  notes: string;
  total: number;
}

// PayPal response interface
interface PayPalResponse {
  status: string;
  message: string;
  result: Somast;
}

// placeOrderPaypal function
export const placeOrderPaypal = async (data: PayPalPaymentData) =>
  await postData<PayPalResponse>(`${API_URL}/finishOrder_paypal`, data);


// Interface for the ThreeDSecure object
interface ThreeDSecure {
  browser: {
    accept_header: string;
    java_enabled: boolean;
    language: string;
    color_depth: string;
    screen_height: number;
    screen_width: number;
    time_zone: number;
    user_agent: string;
    javascript_enabled: boolean;
  };
  enabled: boolean;
  version: number;
  auth_required: boolean;
}

// Interface for the CreditCard object
interface CreditCard {
  name: string;
  number: string;
  expiry_month: string;
  expiry_year: string;
  '3d_secure': ThreeDSecure;
}

// Interface for the PaymentDataBambora object
interface PaymentDataBambora {
  amount: number;
  term_url: string;
  card: CreditCard;
}
interface PaymentResponse {
  status: string;
  message: string;
  result: string;
}

interface reserveOrderNumberData{
  custno: number;
}

interface NewSonoBamboraResponse {
  status: string;
  message: string;
  orderNumber: string;
}

interface removeReservedOrderNumberData {
  orderNumber: number;
}

interface RemoveReservedSonoResponse {
  status: string;   // 'success' or 'error'
  message: string;  // Detailed success or error message
}


// Visa/Mastercard payment data interface
interface CreditCardPaymentData {
  orderNumber: number;
  custno: number;
  addressID: number;
  pickupInstore: boolean;
  subtotal: string;
  hst: number;
  shipping: number;
  shippingDays: number;
  notes: string;
}

// Get order number updated and reserve an order slot
export const reserveOrderSlot = async (data: reserveOrderNumberData) =>
  await postData<NewSonoBamboraResponse>(`${API_URL}/reserveOrderSlot`, data);

export const storeOrderData = async (data: CreditCardPaymentData) =>
  await postData<NewSonoBamboraResponse>(`${API_URL}/storeOrderData`, data);

// Remove reserved order number if the payment is unsucessful
export const removeReservedOrderSlot = async (data: removeReservedOrderNumberData) =>
  await postData<RemoveReservedSonoResponse>(`${API_URL}/removeReservedOrderSlot`, data);


export const placeOrder = async (data: PaymentDataBambora) =>
  await postData<PaymentResponse>(`${API_URL}/finishOrder`, data);

// Finish Quote Order Payal
export const finishOrderPaypalQuote = async (payload: {sono: string, custno: number}) =>
  await postData<{ status: string; message: string; result:Somast}>(`${API_URL}/finishOrder_paypal_quote`, payload);


//Delete pending order
export const deleteOrder = async (payload: {sono:string}) =>
  await postData<{ status: string; message: string; result: string}>(`${API_URL}/deleteOrder`, payload);


//Address Book Handlings
export interface AddressBook {
  address: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  tel: string;
  forename: string;
  surname: string;
  cust_id: number;
  id: number;
}

interface AddressBookPayload {
  userID: number;
  data: {
    address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    tel: string;
    forename: string;
    surname: string;
  };
}

interface AddressBookResponse {
  addressBook: AddressBook[];  // Adjust this type based on your AddressBook model structure
}

export const newShippingAddress = async (payload: AddressBookPayload) =>
  await postData<AddressBookResponse>(`${API_URL}/newShippingAdd`, payload);

export const deleteShippingAddress = async (payload: {id:number}) =>
  await postData<AddressBookResponse>(`${API_URL}/deleteAddress`, payload);

export const changeShippingAddress = async (payload: {id:number}) =>
  await postData<CheckoutItemsResponse>(`${API_URL}/changeAddress`, payload);

//Dealer Order History 
export interface DealerOneOrderResponse {
  somast: DealerSomast;
  oneOrder: OneOrderProduct[];
  status: string;
  address: AddressBookDealerReceive;
  dealerInfo: DealerInfo;
}

interface DealerSomast {
  id: number;
  order_num: string;
  account: string;
  subtotal: number;
  tax: number;
  shipping: number;
  date_order: string;
  sales_status: number;
  courier: string;
  track_num: string;
  notes: string | null;
  address: string;
  dealer: Dealer;
}

export const getDealerOneOrder = async (so: string, account: string) =>
  await fetchData<DealerOneOrderResponse>(`${API_URL}/oneOrder_dealer_finish?so=${so}&account=${account}`);

//Banner for home page
// Define the type for the banner data
interface Banner {
  seq: number;
  img_path: string;
  link: string;
}

// Fetch banner data from the API
export const getBanner = async () => {
  return fetchData<Banner[]>(`${API_URL}/banner`);
};
