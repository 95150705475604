import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";

interface PaginationComponentProps {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalPages: number;
}

export const PaginationComponent = ({ currentPage, setCurrentPage, totalPages }: PaginationComponentProps) => {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  let startPage, endPage;

  if (totalPages <= 4) {
    // Less than 4 total pages so show all
    startPage = 1;
    endPage = totalPages;
  } else {
    // More than 4 total pages
    if (currentPage <= 3) {
      startPage = 1;
      endPage = 4;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 3;
      endPage = totalPages;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 2;
    }
  }

  return (
    <Pagination className="mt-10">
      <PaginationContent>
        <PaginationItem className="cursor-pointer">
          <PaginationPrevious onClick={() => setCurrentPage(Math.max(1, currentPage - 1))} />
        </PaginationItem>
        {startPage > 1 && (
          <>
            <PaginationItem className="cursor-pointer">
              <PaginationLink onClick={() => setCurrentPage(1)}>1</PaginationLink>
            </PaginationItem>
            {startPage > 2 && (
              <PaginationItem>
                <PaginationEllipsis />
              </PaginationItem>
            )}
          </>
        )}
        {pages.slice(startPage - 1, endPage).map((page) => (
          <PaginationItem key={page} className={`cursor-pointer`}>
            <PaginationLink onClick={() => setCurrentPage(page)} isActive={currentPage === page}>
              {page}
            </PaginationLink>
          </PaginationItem>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && (
              <PaginationItem>
                <PaginationEllipsis />
              </PaginationItem>
            )}
            <PaginationItem className="cursor-pointer">
              <PaginationLink onClick={() => setCurrentPage(totalPages)}>{totalPages}</PaginationLink>
            </PaginationItem>
          </>
        )}
        <PaginationItem className="cursor-pointer">
          <PaginationNext onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))} />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};
