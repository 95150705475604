import { PRIVACY_POLICY, PrivacyPolicy } from "@/utils/constants";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import Sidebar from "./components/sidebar";
import PricingPolicy from "./components/pricingPolicy";
import ReturnPolicy from "./components/returnPolicy";
import ShippingAndDelivery from "./components/shippingAndDelivery";
import WarrantyAndDamage from "./components/warrantyAndDamage";
import { useSearchParams } from "react-router-dom";

const PrivacyPolicyLayout = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") as PrivacyPolicy;

  const [activeTab, setActiveTab] = useState(PRIVACY_POLICY[0]);

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    } else {
      setActiveTab(PRIVACY_POLICY[0]);
    }
  }, [tab]);

  return (
    <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
      <aside className="lg:w-1/5">
        <Sidebar activeTab={activeTab} setActiveTab={(tab) => setActiveTab(tab)} />
      </aside>
      <div className={cn("flex-1 lg:max-w-3xl")}>
        {activeTab === "Pricing Policy" && <PricingPolicy />}
        {activeTab === "Return Policy" && <ReturnPolicy />}
        {activeTab === "Shipping and Delivery" && <ShippingAndDelivery />}
        {activeTab === "Warranty and Damage" && <WarrantyAndDamage />}
      </div>
    </div>
  );
};

export default PrivacyPolicyLayout;
