// components/Loader.tsx
import React from 'react';
import { Loader as Spinner } from 'lucide-react';

const Loader: React.FC = () => {
  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex flex-col items-center justify-center z-50">
      <Spinner className="animate-spin text-white w-12 h-12" />
      <p className="text-white mt-4 text-center text-lg">
        Processing… Please do not refresh or click back on the webpage.
      </p>
    </div>
  );
};

export default Loader;
