import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Control, UseFormSetValue } from "react-hook-form";
import { ConfirmOrderDealer } from "../types/Schema";
import { FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { getProvincesAndStates } from "@/utils/helpers/getProvincesAndStates";
import { formatPhoneNumber, isCanadianPhoneNumber } from "@/utils/helpers/formatPhoneNumber";

interface ShippingFormProps {
  control: Control<ConfirmOrderDealer>;
  watchcountry: "CA" | "USA";
  setValue: UseFormSetValue<ConfirmOrderDealer>;
  disabled?: boolean; // Optional disabled prop
}

const ShippingForm = ({ control, watchcountry, setValue, disabled = false }: ShippingFormProps) => {
  const regions = getProvincesAndStates(); // Retrieves regions based on the country
  
  return (
    <div className="grid gap-5 md:grid-cols-2">
      <div className="space-y-4">
        <h4 className="font-bold">Shipping To</h4>
        {/* Company */}
        <FormField
          control={control}
          name="company"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input {...field} placeholder="Company" className="border-[#BEBEBE] h-14" disabled={disabled} />
              </FormControl>
            </FormItem>
          )}
        />
        {/* Address */}
        <FormField
          control={control}
          name="address"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input {...field} placeholder="Address" className="border-[#BEBEBE] h-14" disabled={disabled} />
              </FormControl>
            </FormItem>
          )}
        />
        <div className="grid grid-cols-2 gap-3">
          {/* City */}
          <FormField
            name="city"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="*City" {...field} className="border-[#BEBEBE] h-14" disabled={disabled} />
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
          {/* Postal Code */}
          <FormField
            name="postalCode"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="*Postal Code" {...field} className="border-[#BEBEBE] h-14" disabled={disabled} />
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-3">
          {/* Country */}
          <FormField
            name="country"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Select 
                    onValueChange={(value) => {
                      field.onChange(value);
                      setValue("province", "");
                    }} 
                    value={field.value} 
                    disabled={disabled} 
                  >
                    <SelectTrigger className="border-[#BEBEBE] h-14">
                      <SelectValue placeholder="*Country" className="text-[#BEBEBE]" />
                    </SelectTrigger>
                    <SelectContent className="text-[#BEBEBE]">
                      <SelectItem value="CA">Canada</SelectItem>
                      <SelectItem value="USA">USA</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
          {/* Province/State */}
          <FormField
            name="province"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Select onValueChange={(value) => {
                    field.onChange(value);
                    setValue("province", value);
                  }} value={field.value} disabled={disabled}>
                    <SelectTrigger className="border-[#BEBEBE] h-14">
                      <SelectValue placeholder="*Province" className="text-[#BEBEBE]" />
                    </SelectTrigger>
                    <SelectContent className="text-[#BEBEBE]">
                      <SelectGroup>
                        {(watchcountry === "CA" ? regions.canada : regions.usa).map((region) => (
                          <SelectItem key={region.value} value={region.value}>
                            {region.label}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
        </div>
        {/* Telephone */}
        <FormField
          name="tel"
          control={control}
          rules={{ validate: (value) => isCanadianPhoneNumber(value) || "Invalid Canadian phone number" }}
          render={({ field, fieldState: { error } }) => (
            <FormItem>
              <FormControl>
                <Input 
                  placeholder="*Tel" 
                  {...field} 
                  className="border-[#BEBEBE] h-14" 
                  disabled={disabled} 
                  type="tel"
                  onBlur={(e) => {
                    field.onBlur();
                    e.currentTarget.value = formatPhoneNumber(e.currentTarget.value);
                  }}
                />
              </FormControl>
              <FormMessage className="mt-1">{error && <span className="text-red-500">{error.message}</span>}</FormMessage>
            </FormItem>
          )}
        />
      </div>
      <div className="space-y-3">
        <h4 className="font-bold">Notes to shop</h4>
        <FormField
          name="notes"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea placeholder="Write your messages" rows={14} {...field} className="border-[#BEBEBE] h-[21.8rem]" />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};


export default ShippingForm;
