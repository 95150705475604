import { Button } from "./ui/button";

interface SectionTitleProps {
  title: string;
  subTitle: string;
  category: string;
  onRefresh: () => void;
}

const SectionTitle = ({ title, subTitle, category, onRefresh }: SectionTitleProps) => {
  return (
    <div className="relative flex justify-center items-center px-4 md:px-8 my-6 space-y-6 md:flex-row md:space-y-0 md:space-x-6">
      <div className="relative h-20 w-full md:w-auto">
        <h1 className=" font-black z-10 absolute text-[#ECECEC] uppercase tracking-[.2em] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-b-8 border-[#FFE51266] tracking-wider w-max text-4xl md:text-6xl text-center md:text-left">
          {title}
        </h1>
        <h3
          className="z-30 uppercase tracking-widest font-black text-center relative
          text-lg md:text-5xl
        "
        >
          {subTitle}
        </h3>
        <h5 className="z-20 capitalize text-center relative">{category}</h5>
      </div>
      <Button
        variant={"outline"}
        onClick={onRefresh}
        className="italic border-[#7E7E7E] rounded-lg md:self-end absolute right-5 top-2/3 md:top-1/3"
      >
        refresh new
      </Button>
    </div>
  );
};

export default SectionTitle;
