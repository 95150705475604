//import BannerImage from "@/assets/images/Home Banner.png";
import ContactUsForm from "./components/contactUsForm";

const ContactPage = () => {
  return (
    <div className="container mx-auto px-4 md:px-20 my-48">
      <h1 className="text-center font-black text-[#3D3D3D] text-3xl ">Contact Us</h1>
      <div className="my-5 max-w-lg mx-auto">
        <div className="flex gap-5 w-full my-2 align-top">
          <strong className="w-2/5 text-end">Address:</strong>
          <p className="w-3/5 leading-6">
            170 Zenway Blvd. Unit 2 <br /> Woodbridge, ON., L4H 2Y7 CANADA
          </p>
        </div>
        <div className="flex gap-5 w-full my-2">
          <strong className="text-end w-2/5">Telephone:</strong>
          <p className="w-3/5 leading-6">905-850-3433</p>
        </div>
        <div className="flex gap-5 w-full my-2">
          <strong className="text-end w-2/5">Fax:</strong>
          <p className="w-3/5 leading-6">905-850-1722</p>
        </div>{" "}
        <div className="flex gap-5 w-full my-2">
          <strong className="text-end w-2/5">Business hours:</strong>
          <p className="w-3/5 leading-6">
            Monday - Friday : 8:30am to 5:00pm <br />
            Saturday: Appointment only
          </p>
        </div>
      </div>

      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.8862318308334!2d-79.6354286482207!3d43.77522039624773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3b4cdc066897%3A0x3b04eb8c2646914d!2s170+Zenway+Blvd+%232%2C+Woodbridge%2C+ON+L4H+2Y7!5e0!3m2!1sen!2sca!4v1514931686154"
          width="100%"
          height={500}
          allowFullScreen={true}
          style={{ border: 0 }}
        />
      </div>
      <h1 className="text-center font-black text-[#3D3D3D] text-xl mt-10">Send us a message</h1>
      <ContactUsForm />
    </div>
  );
};

export default ContactPage;
