import { EXCLUDE_ROUTES } from "@/utils/constants";
import React from "react";
import { matchPath, useLocation } from "react-router";
import Header from "./header/header";
import Navbar from "./navbar/navbar";
import Footer from "./footer/footer";

const AppLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const shouldExclude = EXCLUDE_ROUTES.some((pattern) => matchPath(pattern, pathname));

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Header />

      {!shouldExclude && <Navbar />}

      {/* div to act as spacer for sticky header */}
      <div className="flex h-16 w-full items-center justify-between px-4 md:px-6"></div>

      <main style={{ flexGrow: 1 }}>{children}</main>

      <Footer />
    </div>
  );
};

export default AppLayout;
