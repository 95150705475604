import { isCanadianPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { z } from "zod";

export const ConfirmOrderSchema = z.object({
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  address: z.string().min(2),
  city: z.string().min(2),
  postalCode: z.string().min(2),
  country: z.string().min(2),
  province: z.string().min(2),
  tel: z
  .string()
  .min(2)
  .refine((value) => isCanadianPhoneNumber(value), {
    message: "Invalid phone number",
  }),
  notes: z.string(),
  storePickup: z.boolean(),
});

export type ConfirmOrder = z.infer<typeof ConfirmOrderSchema>;
