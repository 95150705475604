import { ROUTES } from "@/utils/constants";
import { Link } from "react-router-dom";
import PaymentOption from "@/assets/images/paymentOption.png";

const Footer = () => {
  return (
    <footer className="bg-[#3D3D3D] text-white">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-center">
          <div className="grid grid-cols-2 gap-8 sm:gap-10 sm:grid-cols-4">
            <div>
              <h2 className="mb-6 text-md font-semibold capitalize">Products</h2>
              <ul className="tefont-light space-y-2 text-sm">
                {ROUTES.filter((route) => route.name !== "Contact Us").map((route) => (
                  <li key={route.path}>
                    <Link to={route.path} className="hover:underline">
                      {route.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-md font-semibold capitalize">My Accounts</h2>
              <ul className="space-y-2 text-sm">
                <li>
                  <Link to={"/register"} className="hover:underline">
                    Sign Up
                  </Link>{" "}
                  /{" "}
                  <Link to={"/sign-in"} className="hover:underline">
                    Sign In
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-md font-semibold capitalize">Shipping</h2>
              <ul className="space-y-2 text-sm">
                <li>
                  <Link to={"/privacy-policy"} className="hover:underline">
                    Pricing Policy
                  </Link>
                </li>
                <li>
                  <Link to={"/privacy-policy?tab=Return Policy"} className="hover:underline">
                    Return Policy
                  </Link>
                </li>
                <li>
                  <Link to={"/privacy-policy?tab=Shipping and Delivery"} className="hover:underline">
                    Shipping and Delivery
                  </Link>
                </li>
                <li>
                  <Link to={"/privacy-policy?tab=Warranty and Damage"} className="hover:underline">
                    Warranty and Damage
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-md font-semibold capitalize">Contact Us</h2>
              <ul className="space-y-2 text-sm">
                <li>
                  <Link to={"/contact-us"} className="hover:underline">
                    Contact Us
                  </Link>
                </li>
                <li className="mt-10">
                <img src={PaymentOption} alt="Payment options: paypal, mastercard, visa" className=" object-contain" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="sm:flex sm:items-center sm:justify-center mt-5">
          
          <span className="text-sm sm:text-center">

            © {new Date().getFullYear()} Golden Leaf Automotive. All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
