/**
 * Checks if a postal code is valid for a given country.
 * @param country - The country to validate the postal code for. Can be "usa" or "canada".
 * @param postalCode - The postal code to be validated.
 * @returns A boolean indicating whether the postal code is valid or not.
 */
export function isValidPostalCode(country: "USA" | "CA", postalCode: string): boolean {
  const canadaPostalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  const usaZipCodeRegex = /^\d{5}(-\d{4})?$/;

  if (country === "CA") {
    return canadaPostalCodeRegex.test(postalCode);
  } else if (country === "USA") {
    return usaZipCodeRegex.test(postalCode);
  } else {
    return false; // Country not supported
  }
}
