const ReturnPolicy = () => {
  return (
    <div className="my-10">
      <h1 className="font-bold ml-10">Return Policy</h1>
      <h4 className="mt-10 font-bold">Refused Shipments</h4>
      <p className="mt-5 leading-6">
        Shipping and other costs incurred by GLA while attempting to deliver merchandise which is refused will be billed
        to the customer at the actual cost. Items Damaged in transit are the responsibility of the carrier. Please
        retain all packing materials and immediately contact the courier (i.e., UPS, FedEx, DHL, U.S. Postal Service,
        Canada Post, truck line) to file a damage claim. Please do not return damaged items to GLA. We will, however, be
        happy to assist you in filing your claim with the carrier.{" "}
      </p>
      <p className="mt-5">General policy on core returns:</p>
      <ul className="list-disc mt-2 ml-5">
        <li>No broken or missing parts, cracked cases, deeply grooved surfaces etc.</li>
        <li>Moveable items must not be frozen, locked or rusted solid.</li>
        <li>No case with torch cuts.</li>
        <li>No welded or bent shafts, ears etc.</li>
        <li>No disassembled cores accepted.</li>
        <li>Returned core must be same part as rebuilt unit purchased.</li>
        <li>Returned core should be returned in same box as rebuilt unit purchased.</li>
        <li>Core return-ability will be determined by the re-manufacturer and/or GLA.</li>
      </ul>
      <h4 className="mt-10 font-bold">Returns - Our Guarantee</h4>
      <p className="mt-5">
        If you are dissatisfied with an item, please contact GLA to obtain return authorization. Missing or damaged
        items must be reported within 24 hours of delivery . After speaking with us, we request that you also enclose a
        brief note describing the problem with the returned merchandise. We will accept the return of any unsatisfactory
        item provided it is unused, in resalable condition and returned with a copy of your invoice within 30 days of
        the invoice date. Return of items ordered in error may be subject to a 25% restocking fee. Please do not return
        merchandise to us C.O.D. or postage due. If GLA is at fault in any way we will promptly refund your return
        freight charges. GLA is NOT responsible for air-freight charges or boxing/packing services. Only courier ground
        rates apply.
      </p>
    </div>
  );
};

export default ReturnPolicy;
