import { z } from "zod";

export const signUpSchema = z
  .object({
    firstName: z.string().min(2, { message: "First name must be at least 2 characters." }),
    lastName: z.string().min(2, { message: "Last name must be at least 2 characters." }),
    email: z.string().email(),
    password: z
      .string()
      .min(8, {
        message:
          "Your password must be a minimum of 8 characters and include at least one number and english character.",
      })
      .refine((data) => {
        const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]{8,}$/;
        return regex.test(data);
      }),
    confirmPassword: z
      .string()
      .min(8, {
        message:
          "Your password must be a minimum of 8 characters and include at least one number and english character.",
      })
      .refine((data) => {
        const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\S]{8,}$/;
        return regex.test(data);
      }),
    recieveUpdates: z.boolean().optional(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match.",
    path: ["confirmPassword"],
  });

export type SignUpSchema = z.infer<typeof signUpSchema>;
