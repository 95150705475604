import React from "react";

interface SectionDividerProps {
  title: string;
}

const SectionDivider: React.FC<SectionDividerProps> = ({ title }) => {
  return (
    <div className="flex items-center w-full max-w-lg">
      <hr className="flex-grow border-t border-gray-400" />
      <span className="mx-2 text-gray-700 font-medium">{title}</span>
      <hr className="flex-grow border-t border-gray-400" />
    </div>
  );
};

export default SectionDivider;
