import { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useSearchParams, useNavigate } from "react-router-dom";
import { QueryKeys, getDealerOneOrder, AddressBookDealerReceive, getItemsInCart } from "@/lib/queries";
import { Button } from "@/components/ui/button";
import OrderTable from "./components/OrderTable";
import OrderSummary from "./components/OrderSummary";
import { AppContext } from "@/context/context";
import { OneOrderProduct } from "@/interfaces/product.interface";
import { Dealer, DealerInfo } from "@/interfaces/dealer.interface";


const OrderReceiptDealerPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state } = useContext(AppContext);

  // Type assertion to ensure we're treating the user as a Dealer
  const dealer = state.user as Dealer;

  // Read URL parameters
  const userIdFromUrl = searchParams.get("id");
  const orderSonoFromUrl = searchParams.get("so");

  // Determine the user ID and order number to use
  const userId = userIdFromUrl || dealer.id;
  const orderSono = orderSonoFromUrl || state.orderSono;
  const dealerAccount = dealer.account;

  // Fetch the specific order data using getDealerOneOrder
  const { data: orderData } = useQuery(
    [QueryKeys.ONE_ORDER, orderSono, userId],
    () => getDealerOneOrder(String(orderSono), String(userId)),
    {
      enabled: !!orderSono && !!dealerAccount,
    }
  );

    // Fetch cart items for the header cart icon
    const dealerUser = state.user as Dealer;
    const { refetch: refetchCart } = useQuery(
      QueryKeys.CART,
      () => getItemsInCart({
        user: state.user?.id as number,
        type: dealerUser && dealerUser.account ? 2 : 1
      }),
      {
        enabled: !!state.user,
        refetchOnWindowFocus: false,
      }
    );

  // Placeholder for dynamically fetched data (mockup)
  const dealerInfo: DealerInfo = orderData?.dealerInfo || {} as DealerInfo;
  const carts: OneOrderProduct[] = orderData?.oneOrder || [];
  const subtotal = orderData?.somast.subtotal || 0;
  const taxTotal = orderData?.somast.tax || 0;
  const totalShipping = orderData?.somast.shipping || 0;
  const total = subtotal + taxTotal + totalShipping;

  // Determine which address to use
  const shippingAddress: AddressBookDealerReceive = orderData?.address || {} as AddressBookDealerReceive;

  useEffect(() => {
    if (orderData) {
      refetchCart();
    }
  }, [orderData, refetchCart]);
  
  // Check if shipping address is empty
  const isShippingAddressEmpty = !shippingAddress.address || shippingAddress.address.trim() === "";

  return (
    <div className="container mx-auto my-44">
      <h1 className="text-center font-black text-[#3D3D3D] text-3xl my-5">Order Receipt</h1>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="col-span-2 space-y-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div>
              <div className="flex justify-between items-center mb-2 h-10 rounded-3xl bg-[#3D3D3D]">
                <h4 className="text-lg font-semibold text-white ml-4">Shipping to</h4>
              </div>
              <div className="bg-white shadow-md py-6 px-4 rounded-lg space-y-2">
                {isShippingAddressEmpty ? (
                  <>
                    <p className="text-base font-semibold">
                      {dealerInfo.company}
                    </p>
                    <p>
                      {dealerInfo.address1}, <br /> {dealerInfo.city}, {dealerInfo.state} {dealerInfo.zip} <br />
                      {dealerInfo.country}
                    </p>
                    <p>{dealerInfo.phone}</p>
                  </>
                ) : (
                  <>
                    <p className="text-base font-semibold">
                      {shippingAddress.company}
                    </p>
                    <p>
                      {shippingAddress.address}, <br /> {shippingAddress.city}, {shippingAddress.province},{" "}
                      {shippingAddress.postalcode} <br />
                      {shippingAddress.country}
                    </p>
                    <p>{shippingAddress.tel}</p>
                  </>
                )}
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2 h-10 rounded-3xl bg-[#3D3D3D]">
                <h2 className="text-lg font-semibold text-white ml-4">Billing to</h2>
              </div>
              <div className="bg-white shadow-md py-6 px-4 rounded-lg space-y-2">
                <p className="text-base font-semibold">
                  {dealerInfo.company}
                </p>
                <p>
                  {dealerInfo.address1}, <br /> {dealerInfo.city}, {dealerInfo.state} {dealerInfo.zip} <br />
                  {dealerInfo.country}
                </p>
                <p>{dealerInfo.phone}</p>
              </div>
            </div>
          </div>
          <OrderTable items={carts} />
        </div>
        <div className="col-span-1">
          <OrderSummary
            subtotal={subtotal}
            shipping={totalShipping}
            tax={taxTotal}
            total={total}
          />
        </div>
      </div>

      <div className="mt-20 auto-mx flex justify-center gap-3 grid grid-cols-1">
        <div className="text-center text-2xl font-bold">
          <p>
            Thank you for shopping with Golden Leaf Automotive! <br /> Your order number is: {orderSono}
          </p>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 mt-6">
          <Button
            className="bg-[#184178] h-12 hover:bg-[#184178] rounded-2xl text-md text-white font-bold mx-10 my-2 md:my-0"
            type="button"
            onClick={() => navigate("/profile?tab=Order History")}
          >
            Order History
          </Button>
          <Button
            className="bg-white hover:bg-gray-100 border-2 border-[#184178] h-12 rounded-2xl text-md text-[#184178] font-black md:w-4/5 shadow-md mx-10 my-2 md:my-0"
            type="button"
            onClick={() => navigate("/")}
          >
            Back to Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderReceiptDealerPage;
