import { AppContext } from "@/context/context";
import { Dealer } from "@/interfaces/dealer.interface";
import { TABS, tab } from "@/utils/constants";
import { format, subDays } from "date-fns"; // Import subDays
import { Menu, X } from "lucide-react";
import { useContext, useState } from "react";
import MobileNav from "./mobileNav";

interface SidebarProps {
  activeTab: tab;
  setActiveTab: (tab: tab) => void;
}

const Sidebar = ({ activeTab, setActiveTab }: SidebarProps) => {
  const { dispatch, state } = useContext(AppContext);

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const logout = () => {
    dispatch({ type: "LOGOUT", payload: null });
    window.location.reload();  // Force page refresh after logging out
  };

  const today = new Date();
  const yesterday = subDays(today, 1); // Subtract one day from the current date
  const user = state.user as Dealer;

  // Filter tabs based on user's account status
  const filteredTabs = user.account ? TABS.filter((tab) => tab !== "Pending Orders") : TABS.filter((tab) => tab !== "Download Price Sheet");

  return (
    <div className="bg-[#ECECEC] md:min-h-[calc(100vh-64px)] h-full flex md:justify-end sticky top-0 px-9 md:px z-10">
      <div className="flex flex-col md:mt-[17rem] my-5">
        <ul className="mr-5 space-y-2 hidden md:flex md:flex-col">
          {filteredTabs.map((tab, index) => (
            <li key={index} className="cursor-pointer" onClick={() => setActiveTab(tab)}>
              <div className={`text-end hover:text-[#E5B80B] ${activeTab === tab ? "text-[#E5B80B] font-bold" : ""}`}>
                {tab === "Download Price Sheet" ? `Download Price Sheet AS of ${format(yesterday, "yyyy-MM-dd")}` : tab}
              </div>
            </li>
          ))}
          <li
            className="cursor-pointer"
            onClick={() => {
              logout();
            }}
          >
            <div className={`text-end hover:text-[#E5B80B] `}>Logout</div>
          </li>
        </ul>
        <button
          className="flex items-center space-x-2 ml-2 md:hidden gap-4"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          {showMobileMenu ? <X /> : <Menu />} {activeTab}
        </button>
        {showMobileMenu && (
          <MobileNav
            setCloseMenu={(bool) => setShowMobileMenu(bool)}
            tabs={filteredTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            logout={logout}
          />
        )}
      </div>
    </div>
  );
};

export default Sidebar;
