// main.tsx
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./styles/index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./context/context.tsx";
import { ToastContainer } from 'react-toastify';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <AppProvider>
    <QueryClientProvider client={queryClient}>
      <GoogleReCaptchaProvider reCaptchaKey="6LdP0toUAAAAAF6GzEK38keVcIHkBnB7KQuxRPqR">
        <BrowserRouter>
          <App />
          <ToastContainer 
            className="mt-[124px]"
            autoClose={3000}
          />
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    </QueryClientProvider>
  </AppProvider>
);
