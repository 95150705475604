import { AppContext } from "@/context/context";
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const { state } = useContext(AppContext);
  if (!state.user) return <Navigate to="/sign-in" />;
  return <Outlet />;
};

export default PrivateRoute;
