import { Dealer, DealerInfo } from "@/interfaces/dealer.interface";
import { BillingInfo, User, UserInfo } from "@/interfaces/user.interface";
import React, { createContext, useEffect, useReducer } from "react";

type State = {
  user: User | Dealer | null;
  userInfo: UserInfo | DealerInfo | null;
  billingInfo: BillingInfo | null;
  cart: any[];
  orderSono: number | null;
  order: any | null; 
};

type Action = {
  type: string;
  payload: any;
};

const initialState: State = JSON.parse(localStorage.getItem("appState") || "null") || {
  user: null,
  userInfo: null,
  billingInfo: null,
  cart: [],
  orderSono: null,
  order: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload as User | Dealer,
      };
    case "SET_ORDER_SONO":
      return {
        ...state,
        orderSono: action.payload,
      };
    case "SET_USER_INFO":
      return {
        ...state,
        userInfo: action.payload as UserInfo | DealerInfo,
      };
    case "SET_BILLING_INFO":
      return {
        ...state,
        billingInfo: action.payload as BillingInfo,
      };
    case "ADD_TO_CART":
      const existingCartItem = state.cart.find((item) => item.id === action.payload.id);
      if (existingCartItem) {
        return {
          ...state,
          cart: state.cart.map((item) =>
            item.id === action.payload.id ? { ...item, quantity: item.quantity + action.payload.quantity } : item
          ),
        };
      } else {
        return {
          ...state,
          cart: [...state.cart, { id: action.payload.id, quantity: action.payload.quantity }],
        };
      }
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload.id),
      };
    case "UPDATE_CART":
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.id === action.payload.id ? { ...item, quantity: action.payload.quantity } : item
        ),
      };
    case "CLEAR_CART":
      return {
        ...state,
        cart: [],
      };
    case "SET_ORDER":
      return {
        ...state,
        order: action.payload,
      };
    case "LOGOUT":
      return {
        user: null,
        userInfo: null,
        billingInfo: null,
        cart: [],
        orderSono: null,
        order: null,
      };
    default:
      return state;
  }
};

// Create context
export const AppContext = createContext({} as { state: State; dispatch: React.Dispatch<Action> });

// Provider component
export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Save state to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("appState", JSON.stringify(state));
  }, [state]);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};
