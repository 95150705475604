import CatalogDetailDialog from "@/components/dialog/catalogDetailDialog";
import SectionCard from "@/components/sectionCard";
import { Button } from "@/components/ui/button";
import { ClassicBodyModelEnum } from "@/interfaces/product.interface";
import { QueryKeys, getClassicModels } from "@/lib/queries";
import { Loader } from "lucide-react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
 import { IMAGE_URL } from "@/config/appConfig";

const ClassicBodyModelPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const Model = params.model as ClassicBodyModelEnum;

  const { data: models, isLoading } = useQuery(QueryKeys.CLASSIC_MODELS, () => getClassicModels(), {
    refetchOnWindowFocus: false,
  });

  const [productId, setProductId] = useState<string>("");
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const filteredModels = models && models.models.length > 0 && models.models.filter((m) => m.model === Model);

  return (
    <div className="container mx-auto mt-44 mb-24">
      <div className="flex justify-center items-center flex-col gap-5">
        <Button className="bg-[#FFE512] hover:bg-[#FFE512] text-black font-bold" onClick={() => navigate(-1)}>
          Return to models
        </Button>
        <p className="italic text-[#3D3D3D] text-sm mb-5 font-black z-10">
          {filteredModels && filteredModels.length} products found
        </p>
      </div>
      {isLoading && (
        <div className="w-full h-full flex items-center justify-center">
          <Loader className="animate-spin h-16 w-16" />
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-fit mx-auto">
        {filteredModels &&
          filteredModels.length > 0 &&
          filteredModels.map((model, index) => {
            //console.log(model);
            return (
              <SectionCard
                key={index}
                code={model.item}
                price={" "}
                title={model.year_model}
                make={model.make}
                year=" "
                 image={IMAGE_URL + "/images/classicBody/" + model.item + ".jpg" 
                  || IMAGE_URL + "/images/classicBody/" + model.item + "-Side-View.jpg"
                  || IMAGE_URL + "/images/classicBody/" + model.item + "-Front-View.jpg"
                  || IMAGE_URL + "/images/classicBody/" + model.item + "-Back-View.jpg"
                 }
                imageClassName=""
                className=""
                onClick={() => {
                  setProductId(model.item);
                  setShowDialog(true);
                }}
              />
            );
          })}
      </div>

      {showDialog && (
        <CatalogDetailDialog open={showDialog} onClose={() => setShowDialog(false)} productCode={productId} />
      )}
    </div>
  );
};

export default ClassicBodyModelPage;
