/**
 * Checks if a phone number is in the Canadian format.
 * Canadian phone numbers have the format: (NXX)-NXX-XXXX
 * N is 2-9, X is 0-9
 * Examples: (123)-456-7890, 123-456-7890, 123.456.7890, 123 456 7890, +1 (123)-456-7890, 123/456-7890
 *
 * @param phoneNumber - The phone number to check.
 * @returns A boolean indicating whether the phone number is in the Canadian format.
 */
export function isCanadianPhoneNumber(phoneNumber: string): boolean {
  // Canadian phone numbers have the format: (NXX)-NXX-XXXX or NXX/NXX-XXXX
  // N is 2-9, X is 0-9
  // Examples: (123)-456-7890, 123-456-7890, 123.456.7890, 123 456 7890, +1 (123)-456-7890, 123/456-7890
  const regex = /^(\+1|1)?\(?([2-9][0-9]{2})\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$|^(\+1|1)?\(?([2-9][0-9]{2})\)?[\/-]?([2-9][0-9]{2})[-]?([0-9]{4})$/;
  return regex.test(phoneNumber);
}

/**
 * Formats a phone number by removing non-digit characters and applying a standard format.
 * If the phone number cannot be formatted, it is returned as is.
 *
 * @param phoneNumber - The phone number to format.
 * @returns The formatted phone number.
 */
export function formatPhoneNumber(phoneNumber: string): string {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumber;
}
