import { cn } from "@/lib/utils";
import { ROUTES } from "@/utils/constants";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <div className="justify-center hidden md:flex">
      <nav className=" mx-auto border w-7/12 fixed top-36 bg-white shadow-md shadow-gray-400 rounded-lg px-4 md:px-6 h-12 z-50">
        <ul className="flex justify-between items-center h-full mx-1">
          {ROUTES.map((route) => (
            <li key={route.name}>
              <Link
                to={route.path}
                className={cn(
                  pathname === route.path ? "text-[#FFE512] font-black" : "text-[#3D3D3D] font-semibold hover:text-[#FFE512]"
                )}
              >
                {route.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
