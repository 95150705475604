import SectionCard from "@/components/sectionCard";
import { IMAGE_URL } from "@/config/appConfig";
import { truncate } from "@/lib/utils";
import { useContext, useState} from "react";
import { PaginationComponent } from "../specials/components/paginationComponent";
import ProductDetailDialog from "@/components/dialog/productDetailDialog";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { QueryKeys, fetchSearchResults, specialsProducts } from "@/lib/queries"; // Include specialsProducts query
import { AppContext } from "@/context/context";
import { Dealer } from "@/interfaces/dealer.interface";
import { getPriceAgainstLevel } from "@/utils/helpers/getPriceAgainstLevel";
import { Product } from "@/interfaces/product.interface";
import { Loader } from "lucide-react";

const SearchPage = () => {
  const [searchParams] = useSearchParams();
  const item = searchParams.get("item");
  const make = searchParams.get("make");
  const year = searchParams.get("year");

  const [productId, setProductId] = useState<string>("");
  const [showProductDialog, setShowProductDialog] = useState<boolean>(false);
  const [pages, setPages] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [specialPrices, setSpecialPrices] = useState<{ [key: string]: { salePrice: number; orgPrice: number } }>({}); // Track special prices

  const { data, isLoading } = useQuery(
    [QueryKeys.SEARCH_RESULTS, year, make, currentPage, item],
    () => fetchSearchResults(make as string, parseInt(year as string), item as string, currentPage),
    {
      refetchOnWindowFocus: false,
      enabled: !!make || !!year || !!item || currentPage > 1,
      onSuccess: (data) => {
        const totalPages = Math.ceil(data.items.total / 20);
        const pages = [...Array(totalPages).keys()].map((i) => i + 1);
        setPages(pages);
      },
    }
  );

  const { state } = useContext(AppContext);
  const dealerUser = state.user && (state.user as Dealer);
  const isDealer = dealerUser && dealerUser.account;

  // Fetch special prices for non-dealers
  useQuery(QueryKeys.SPECIAL, specialsProducts, {
    enabled: !isDealer, // Fetch only for non-dealers
    onSuccess: (data) => {
      const specialsMap = data?.item.reduce((acc, product) => {
        acc[product.item] = {
          salePrice: product.sale_price || 0, // Fallback to 0 to ensure it's a number
          orgPrice: product.org_price || 0,   // Fallback to 0 to ensure it's a number
        };
        return acc;
      }, {} as { [key: string]: { salePrice: number; orgPrice: number } });
      setSpecialPrices(specialsMap);
    },
  });

  // Fetch price based on dealer/non-dealer logic
  const fetchPrice = (product: Product) => {
    if (isDealer) {
      const dealerType = Number(dealerUser?.dealer_info?.pricecode) || 0;
      return getPriceAgainstLevel(dealerType, product);
    } else {
      const specialPriceData = specialPrices[product.item];
      if (specialPriceData?.salePrice) {
        return specialPriceData.orgPrice;
      } else if (product.onsale) {
        return product.pricel; // Apply 10% discount for on-sale items
      } else {
        return product.pricel; // Regular price as fallback
      }
    }
  };

  const handleProductClick = (productCode: string) => {
    setProductId(productCode);
    setShowProductDialog(true);
  };

  return (
    <div className="mx-[8%] my-36 px-4 md:px-8">
      <p className="text-center italic text-[#3D3D3D] text-sm mb-5 font-bold z-10">
        {data && data.items.total} products found
      </p>

      {isLoading && (
        <div className="w-full h-full flex items-center justify-center">
          <Loader className="animate-spin h-16 w-16" />
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-fit mx-auto">
        {data &&
          data.items &&
          data.items.data.length > 0 &&
          data.items.data.map((product, index) => {
            const specialPriceData = specialPrices[product.item];
            const specialPrice = specialPriceData?.salePrice ? `CAD ${specialPriceData.salePrice.toFixed(2)}` : undefined;
            //const originalPrice = specialPriceData?.orgPrice ? `CAD ${specialPriceData.orgPrice.toFixed(2)}` : undefined;

            return (
              <SectionCard
                key={index}
                code={product.item}
                price={`CAD ${fetchPrice(product).toFixed(2)}`}
                promoPrice={!isDealer && product.onsale ? `CAD ${(product.pricel * 0.9).toFixed(2)}` : undefined} 
                specialPrice={specialPrice}
                title={truncate(product.descrip, 20)}
                make={product.make}
                year={`${product.year_from}-${product.year_end}`}
                image={IMAGE_URL + product.img_path}
                imageClassName=""
                className=""
                onClick={() => handleProductClick(product.item)}
              />
            );
          })}
      </div>

      {data && data.items.data.length > 0 && (
        <PaginationComponent currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={pages.length} />
      )}

      {showProductDialog && (
        <ProductDetailDialog
          open={showProductDialog}
          onClose={() => setShowProductDialog(false)}
          productCode={productId}
        />
      )}
    </div>
  );
};

export default SearchPage;
