import { Route, Routes } from "react-router";
import HomePage from "./pages/home/page";
import SignInPage from "./pages/signIn/page";
import SignUpPage from "./pages/signup/page";
import PrivateRoute from "./utils/privateRoute";
import CustomerInfo from "./pages/customerInfo/page";
import ProductsPage from "./pages/products/page";
import SingleProductPage from "./pages/products/singleProduct";
import ProfileLayout from "./pages/profile/layout";
import ClassicBodyPage from "./pages/classicBody/page";
import ClassicBodyModelPage from "./pages/classicBody/classicBodyModelPage";
import AppLayout from "./components/Layout";
import NotFoundPage from "./pages/NotFoundPage";
import CatalogPage from "./pages/catalog/page";
import PrivacyPolicyLayout from "./pages/privacyPolicy/layout";
import ContactPage from "./pages/contactUs/page";
import WishlistPage from "./pages/wishlist/page";
import CartPage from "./pages/cart/page";
import ConfirmOrderPage from "./pages/confirmOrder/page";
import ConfirmOrderDealerPage from "./pages/confirmOrderDealer/page";
import PaymentPage from "./pages/payment/page";
import OrderQuotePage from "./pages/orderQuote/page";
import OrderReceiptPage from "./pages/orderReceipt/page";
import OrderReceiptDealerPage from "./pages/orderReceiptDealer/page";
import SpecialPage from "./pages/specials/page";
import PromotionPage from "./pages/promotions/page";
import SearchPage from "./pages/searchPage/page";
import ShippinInquiryPage from "./pages/shippingInquiry/page";
import ForgetPasswordPage from "./pages/forgetpassword/page";
import PendingOrderDetailsPage from "./pages/pendingOrderDetails/page";
import PendingOrderPaymentPage from "./pages/pendingOrderPayment/page";


function App() {
  return (
    <AppLayout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/forgetpassword" element={<ForgetPasswordPage />} />
        <Route path="/register" element={<SignUpPage />} />
        <Route path="/all-products" element={<ProductsPage />} />
        <Route path="/all-products/:productId" element={<SingleProductPage />} />
        <Route path="/specials" element={<SpecialPage />} />
        <Route path="/promotions" element={<PromotionPage />} />
        <Route path="/classic-body" element={<ClassicBodyPage />} />
        <Route path="/classic-body/:model" element={<ClassicBodyModelPage />} />
        <Route path="/shipping-inquiry/:productId" element={<ShippinInquiryPage />} />
        <Route path="/catalog" element={<CatalogPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyLayout />} />
        <Route path="contact-us" element={<ContactPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/confirm-order" element={<ConfirmOrderPage />} />
        <Route path="/dealer-confirm-order" element={<ConfirmOrderDealerPage />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/order-quote" element={<OrderQuotePage />} />
        <Route path="/order-receipt" element={<OrderReceiptPage />} />
        <Route path="/dealer-order-receipt" element={<OrderReceiptDealerPage/>} />
        <Route path="/pending-order-details" element={<PendingOrderDetailsPage />} />
        <Route path="/pending-order-payment" element={<PendingOrderPaymentPage />} />
        <Route path="/customer-info" element={<CustomerInfo />} />
        <Route element={<PrivateRoute />}>
          <Route path="/profile" element={<ProfileLayout />} />
          <Route path="/wishlist" element={<WishlistPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AppLayout>
  );
}

export default App;
