import ProductDetailDialog from "@/components/dialog/productDetailDialog";
import SectionCard from "@/components/sectionCard";
import { IMAGE_URL } from "@/config/appConfig";
import { QueryKeys, fetchSpecialsProducts, specialsProducts } from "@/lib/queries";
import { truncate } from "@/lib/utils";
import { useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { PaginationComponent } from "./components/paginationComponent";
import { AppContext } from "@/context/context";
import { Dealer } from "@/interfaces/dealer.interface";
import { Product } from "@/interfaces/product.interface";
import { getPriceAgainstLevel } from "@/utils/helpers/getPriceAgainstLevel";
import { Loader } from "lucide-react";

const SpecialPage = () => {
  const [productId, setProductId] = useState<string>("");
  const [showProductDialog, setShowProductDialog] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<number[]>([]);
  const [specialsMap, setSpecialsMap] = useState<{ [key: string]: { orgPrice: number, salePrice: number } }>({});

  // Query for specials products
  const { data, isLoading, error } = useQuery(
    [QueryKeys.SPECIAL, currentPage],
    () => fetchSpecialsProducts({ page: currentPage }),
    {
      onSuccess: (data) => {
        const totalPages = Math.ceil(data.special.total / 20);
        const pages = [...Array(totalPages).keys()].map((i) => i + 1);
        setPages(pages);
        console.log("Specials Products Data:", data);
      },
    }
  );

  // Query for holiday data (specials products)
  const { data: holidayData, error: holidayError } = useQuery(QueryKeys.SPECIAL, specialsProducts, {
    onSuccess: (data) => {
      console.log("Holiday Products Data:", data);
    },
  });

  useEffect(() => {
    if (holidayData && Array.isArray(holidayData.item)) {
      const map = holidayData.item.reduce((acc, item) => {
        console.log("Mapping item:", item); // Debugging line
        acc[item.item] = {
          orgPrice: item.org_price || 0,
          salePrice: item.sale_price || 0,
        };
        return acc;
      }, {} as { [key: string]: { orgPrice: number, salePrice: number } });
      setSpecialsMap(map);
    } else {
      console.error("Holiday Data is not an array:", holidayData);
    }
  }, [holidayData]);

  const { state } = useContext(AppContext);
  const dealerUser = state.user && (state.user as Dealer);

  const fetchPrice = (product: Product) => {
    const isDealer = dealerUser && dealerUser.account;
    const priceLevel = getPriceAgainstLevel((dealerUser && (dealerUser.level as number)) || 0, product);
    return isDealer ? priceLevel : product.pricel;
  };

  if (holidayError) {
    console.error("Error fetching holiday data:", holidayError);
  }

  if (isLoading) return (
    <div className="w-full h-full flex items-center justify-center">
      <Loader className="animate-spin h-16 w-16" />
    </div>
  );

  if (error) return <p>Error loading data</p>;

  return (
    <div className="mx-[8%] my-40 px-4 md:px-8">
      <p className="text-center italic text-[#3D3D3D] text-sm mb-5 font-bold z-10">
        {data && data.special.total} products found
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-fit mx-auto">
        {data &&
          data.special &&
          data.special.data.length > 0 &&
          data.special.data.map((product, index) => {
            const specialPrices = specialsMap[product.item];
            const displayPrice = specialPrices && specialPrices.salePrice ? specialPrices.salePrice : fetchPrice(product);
            const originalPrice = specialPrices && specialPrices.orgPrice ? specialPrices.orgPrice : fetchPrice(product);

            return (
              <SectionCard
                key={index}
                code={product.item}
                price={`CAD ${originalPrice.toFixed(2)}`}
                promoPrice={specialPrices && specialPrices.salePrice ? `CAD ${displayPrice.toFixed(2)}` : undefined}
                title={truncate(product.descrip, 20)}
                make={product.make}
                year={product.year_from + "-" + product.year_end}
                image={IMAGE_URL + product.img_path}
                imageClassName=""
                className=""
                onClick={() => {
                  setProductId(product.item);
                  setShowProductDialog(true);
                }}
              />
            );
          })}
      </div>
      {data && data.special.data.length > 0 && (
        <PaginationComponent
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={pages.length}
        />
      )}

      {showProductDialog && (
        <ProductDetailDialog
          open={showProductDialog}
          onClose={() => setShowProductDialog(false)}
          productCode={productId}
        />
      )}
    </div>
  );
};

export default SpecialPage;
