import { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { TABS, tab } from "@/utils/constants";
import Sidebar from "./sidebar";
import Profile from "./components/profile";
import { cn } from "@/lib/utils";
import OrderHistory from "./components/orderHistory";
import PendingOrder from "./components/pendingOrder";
import SubmitInquiry from "./components/submitInquiry";
import ChangePassword from "./components/changePassword";
import { useQuery } from "react-query";
import { QueryKeys, getCustomerOrderHistory } from "@/lib/queries";
import { AppContext } from "@/context/context";
import { Dealer } from "@/interfaces/dealer.interface";
import DealerProfile from "./components/dealer-profile";
import DownloadPriceSheet from "./components/download-price-sheet";

const ProfileLayout = () => {
  const { state } = useContext(AppContext);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<tab>("About Me");

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tab = query.get("tab") as tab;
    if (tab && TABS.includes(tab)) {
      setActiveTab(tab);
    }
  }, [location]);

  useQuery(QueryKeys.ORDER_HISTORY, () => getCustomerOrderHistory(state.user?.id as number), {
    enabled: !!state.user?.id,
    refetchOnWindowFocus: false,
  });

  const dealerInfo = state.user && (state.user as Dealer);

  const renderTabContent = (tab: tab) => {
    switch(tab) {
      case "Order History":
        return <OrderHistory />;
      case "Pending Orders":
        return <PendingOrder />;
      case "Submit Inquiry":
        return <SubmitInquiry />;
      case "Change Password":
        return <ChangePassword />;
      case "About Me":
        return dealerInfo && dealerInfo.account ? <DealerProfile /> : <Profile />;
      case "Download Price Sheet":
        return dealerInfo && dealerInfo.account ? <DownloadPriceSheet /> : null;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0 mt-60 ">
      <aside className="lg:w-1/5 mt-[-11rem]">
        <Sidebar activeTab={activeTab} setActiveTab={setActiveTab} />
      </aside>
      <div
        className={cn(
          "w-full flex-1 lg:max-w-3xl",
          (activeTab === "About Me" ||
            activeTab === "Submit Inquiry" ||
            activeTab === "Change Password" ||
            (activeTab === "Download Price Sheet" && dealerInfo && dealerInfo.account)) &&
            "lg:max-w-xl"
        )}
      >
        {renderTabContent(activeTab)}
      </div>
    </div>
  );
};

export default ProfileLayout;
