import React, { useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { IMAGE_URL } from "@/config/appConfig";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getBanner } from '@/lib/queries';

interface SliderProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

interface BannerData {
  seq: number;
  img_path: string;
  link: string;
}

const NextArrow: React.FC<SliderProps> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-next-arrow`}
      style={{ ...style, display: "block", right: "30px", zIndex: 1 }}
      onClick={onClick}
    />
  );
};

const PrevArrow: React.FC<SliderProps> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-prev-arrow`}
      style={{ ...style, display: "block", left: "30px", zIndex: 1 }}
      onClick={onClick}
    />
  );
};

const Banner: React.FC = () => {
  const [banners, setBanners] = useState<BannerData[]>([]);

  useEffect(() => {
    // Fetch the banners when the component mounts
    const fetchBanners = async () => {
      try {
        const data = await getBanner();
        setBanners(data);
      } catch (error) {
        console.error('Failed to fetch banners:', error);
      }
    };

    fetchBanners();
  }, []);

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots: React.ReactNode) => (
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ul style={{ margin: '0px', padding: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: () => (
      <div
        style={{
          width: '10px',
          height: '10px',
          background: 'rgba(255, 255, 255, 0.5)',
          borderRadius: '50%',
          display: 'inline-block',
          margin: '0 5px'
        }}
      />
    ),
  };

  return (
    <div className="relative w-screen left-0 mb-10" style={{ marginLeft: 'calc(-50vw + 50%)' }}>
      <style>{`
        .slick-dots li.slick-active div {
          background: #fff !important;
          width: 12px;
          height: 12px;
        }
        .custom-arrow {
          width: 35px;
          height: 35px;
        }
        .custom-arrow::before {
          font-size: 35px;
        }
      `}</style>
      <Slider {...settings}>
        {banners.map((banner) => (
          <div key={banner.seq}>
            <a href={banner.link} target="_blank" rel="noopener noreferrer">
              <img src={`${IMAGE_URL + banner.img_path}`} alt={`Banner ${banner.seq}`} className="w-full h-auto object-cover" />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Banner;
