import { Checkbox } from "@/components/ui/checkbox";

interface OrderSummaryProps {
  policy: boolean;
  setPolicy: (policy: boolean) => void;
  subtotal?: number;
  shipping?: number;
  tax?: number;
  total?: number;
}

const OrderSummary = ({
  policy,
  setPolicy,
  subtotal = 0.00,
  shipping = 0.00,
  tax = 0.00,
  total = 0.00,
}: OrderSummaryProps) => {
  // Ensure that values are numbers and default to 0 if not
  const formatCurrency = (value: number) => {
    // Check if the value is a number before calling toFixed
    return typeof value === 'number' && !isNaN(value) ? value.toFixed(2) : '0.00';
  };

  return (
    <div>
      <div className="bg-[#3D3D3D] text-white rounded-[31px] h-10 flex justify-center items-center">
        <h2 className="text-lg font-semibold">Order Summary</h2>
      </div>
      <div className="p-4 mx-4 space-y-2 shadow-md shadow-gray-400">
        <div className="flex justify-between">
          <span className="text-gray-600">Subtotal:</span>
          <span className="font-medium text-gray-900">${formatCurrency(subtotal)} CAD</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">Shipping:</span>
          <span className="font-medium text-gray-900">${formatCurrency(shipping)} CAD</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">Tax:</span>
          <span className="font-medium text-gray-900">${formatCurrency(tax)} CAD</span>
        </div>
        <div className="border-[#3D3D3D] my-4 border-t-2"></div>
        <div className="flex justify-between">
          <span className="text-lg text-gray-900">Total:</span>
          <span className="text-lg font-bold text-gray-900">${formatCurrency(total)} CAD</span>
        </div>
      </div>
      <div className="flex justify-center my-3">
        <div className="flex items-center space-x-2">
          <Checkbox id="terms" checked={policy} onCheckedChange={(value) => setPolicy(value as boolean)} />
          <label
            htmlFor="terms"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            By checking this box, you agree to accept our shipping policy
          </label>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
