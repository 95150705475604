import { Label } from "@/components/ui/label";
import { QueryKeys, downloadPriceSheet, fetchModelList } from "@/lib/queries";
import { useState, useContext, useEffect } from "react";
import { AppContext } from "@/context/context";
import { useMutation, useQuery } from "react-query";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Dealer } from "@/interfaces/dealer.interface";
import { BLANK_URL } from "@/config/appConfig";

const DownloadPriceSheet = () => {
  const { state } = useContext(AppContext);
  const { data: response, isLoading } = useQuery(QueryKeys.MODELS, () => fetchModelList(dealerUser?.dealer_info.pricecode || "all"), {
    refetchOnWindowFocus: false,
  });

  const models = response?.model_list || [];
  const [selectedMake, setSelectedMake] = useState<string>("");
  const [isResponse, setIsResponse] = useState(false);
  const dealerUser = state.user && (state.user as Dealer);

  useEffect(() => {
    if (dealerUser) {
      console.log("Dealer Level:", dealerUser.dealer_info.pricecode);
    }
  }, [dealerUser]);

  const mutation = useMutation(({ level, make }: { level: string, make: string }) => downloadPriceSheet(level, make), {
    onSuccess(data) {
      if (data.status === "success") {
        setIsResponse(true);
      } else {
        setIsResponse(false);
      }
    },
  });

  const onMakeChange = (make: string) => {
    setSelectedMake(make);
    console.log("Selected Make:", make); // Debugging log
    if (dealerUser && dealerUser.dealer_info.pricecode) {
      mutation.mutate({ level: dealerUser.dealer_info.pricecode, make });
    } else {
      console.error("Dealer level is not available.");
    }
  };

  const handleDownloadAll = () => {
    if (dealerUser && dealerUser.dealer_info.pricecode) {
      const url = `${BLANK_URL}Excel/${dealerUser.dealer_info.pricecode}/PriceSheet%20-%20all.xlsx`;
      console.log("Downloading from URL:", url);
      window.open(url, "_blank");
    } else {
      console.error("Dealer level is not available.");
    }
  };

  const handleDownloadSelectedMake = () => {
    if (dealerUser && dealerUser.dealer_info.pricecode) {
      const url = `${BLANK_URL}Excel/${dealerUser.dealer_info.pricecode}/PriceSheet - ${selectedMake}.xlsx`;
      console.log("Downloading from URL:", url);
      window.open(url, "_blank");
    } else {
      console.error("Dealer level is not available.");
    }
  };

  return (
    <div className="my-10">
      <div className="mt-5">
        <h2 className="font-bold text-4xl text-[#3D3D3D]">Download Price Sheet</h2>
      </div>
      <div className="mt-5 max-w-md space-y-2">
        <Label>Select Model</Label>
        <Select
          value={selectedMake}
          onValueChange={(value: string) => onMakeChange(value)}
          disabled={isLoading || mutation.isLoading}
        >
          <SelectTrigger className="border-black">
            <SelectValue placeholder="Model" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {models.length > 0 &&
                models.map((model, index) => (
                  <SelectItem key={index} value={model} className="capitalize">
                    {model}
                  </SelectItem>
                ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <div className="flex items-center mt-5 gap-3">
        <Button 
          onClick={handleDownloadAll} 
          disabled={isLoading || mutation.isLoading || !dealerUser?.dealer_info.pricecode}
        >
          Download All Price Sheets
        </Button>
        {isResponse && (
          <Button 
            variant={"secondary"} 
            disabled={isLoading || mutation.isLoading || !dealerUser?.dealer_info.pricecode}
            onClick={handleDownloadSelectedMake}
          >
            Download Price Sheet for {selectedMake}
          </Button>
        )}
      </div>
    </div>
  );
};

export default DownloadPriceSheet;
