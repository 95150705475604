import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { ProfileSchema, ProfileType } from "../types/Schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { CustomInput } from "@/components/ui/customInput";
import { Mail } from "lucide-react";
import PersonalInformation from "./personal-information";
import ShippingInformation from "./shipping-information";
import BillingInformation from "./billing-information";
import VehicalInformation from "./vehical-information";
import { AppContext } from "@/context/context";
import { useContext, useEffect } from "react";
import { isValidPostalCode } from "@/utils/helpers/isValidPostalCode";
import { toast } from 'react-toastify';
// import { fetchUserInfo, updateUserDetails } from "@/lib/queries";
import { updateUserDetails } from "@/lib/queries";
// import { useMutation, useQuery } from "react-query";
import { useMutation } from "react-query";
import { User, UserInfo } from "@/interfaces/user.interface";

const Profile = () => {
  const { state, dispatch } = useContext(AppContext);

  const user = state.user as User;
  const userInfo = state.userInfo as UserInfo;

  const form = useForm<ProfileType>({
    resolver: zodResolver(ProfileSchema),
    defaultValues: {
      email: user?.email || "",
      changingEmail: "",
      firstName: user?.firstname || "",
      lastName: user?.lastname || "",
      gender:
        userInfo && userInfo.m_gender && userInfo.m_gender === 1
          ? "Male"
          : userInfo && userInfo.m_gender === 2
          ? "Female"
          : "Other" || "",
      dob: (userInfo && userInfo.m_birth) || "",
      address: (userInfo && userInfo.m_address) || "",
      city: (userInfo && userInfo.m_city) || "",
      postalCode: (userInfo && userInfo.m_zipcode) || "",
      country: (userInfo && userInfo.m_country) || "",
      province: (userInfo && userInfo.m_state) || "",
      tel: (userInfo && userInfo.m_tel) || "",
      mobile: (userInfo && userInfo.m_mobile) || "",
      sameAsShipping: false,
      billingAddress: (state.billingInfo && state.billingInfo.address1) || "",
      billingCity: (state.billingInfo && state.billingInfo.city) || "",
      billingPostalCode: (state.billingInfo && state.billingInfo.postalcode) || "",
      billingCountry: (state.billingInfo && state.billingInfo.country) || "",
      billingProvince: (state.billingInfo && state.billingInfo.province) || "",
      billingTel: (state.billingInfo && state.billingInfo.phone) || "",
      billingMobile: (state.billingInfo && state.billingInfo.phone) || "",
      carModel: (userInfo && userInfo.m_car) || "",
      carYear: (userInfo && userInfo.m_year) || "",
      carMake: (userInfo && userInfo.m_make) || "",
    },
  });

  // Reset form with new state data whenever state changes
  useEffect(() => {
    form.reset({
      email: user?.email || "",
      changingEmail: "",
      firstName: user?.firstname || "",
      lastName: user?.lastname || "",
      gender:
        userInfo && userInfo.m_gender === 1
          ? "Male"
          : userInfo && userInfo.m_gender === 2
          ? "Female"
          : "Other",
      dob: userInfo?.m_birth || "",
      address: userInfo?.m_address || "",
      city: userInfo?.m_city || "",
      postalCode: userInfo?.m_zipcode || "",
      country: userInfo?.m_country || "",
      province: userInfo?.m_state || "",
      tel: userInfo?.m_tel || "",
      mobile: userInfo?.m_mobile || "",
      sameAsShipping: false,
      billingAddress: state.billingInfo?.address1 || "",
      billingCity: state.billingInfo?.city || "",
      billingPostalCode: state.billingInfo?.postalcode || "",
      billingCountry: state.billingInfo?.country || "",
      billingProvince: state.billingInfo?.province || "",
      billingTel: state.billingInfo?.phone || "",
      billingMobile: state.billingInfo?.phone || "",
      carModel: userInfo?.m_car || "",
      carYear: userInfo?.m_year || "",
      carMake: userInfo?.m_make || "",
    });
  }, [user, userInfo, state.billingInfo, form]); // dependencies to watch


  const watchSameAsShipping = form.watch("sameAsShipping") as boolean;
  const watchcountry = form.watch("country") as "CA" | "USA";
  const watchbillingCountry = form.watch("billingCountry") as "CA" | "USA";

  const mutation = useMutation(updateUserDetails, {
    onSuccess: (data) => {
      // Update global state to reflect changes
      dispatch({ type: "SET_USER_INFO", payload: data.userinfo });
      dispatch({ type: "SET_BILLING_INFO", payload: data.billing });
      toast.dismiss();
      toast.success("Profile updated successfully");
    },
    onError: (error) => {
      console.log(error);
      toast.dismiss();
      toast.error("Failed to update profile. Please try again.");
    },
  });
  

  const onSubmit = (data: ProfileType) => {
    const validPostCode = isValidPostalCode(data.country as "CA" | "USA", data.postalCode);
    if (!validPostCode) {
      form.setError("postalCode", { message: "Invalid postal code" });
      toast.error("Invalid postal code");
      return;
    }
  
    const validShippingPostCode = isValidPostalCode(
      data.billingCountry as "CA" | "USA",
      data.billingPostalCode as string
    );
    if (!watchSameAsShipping && !validShippingPostCode) {
      form.setError("billingPostalCode", { message: "Invalid billing postal code" });
      toast.error("Invalid billing postal code");
      return;
    }
  
    const payload = {
      data: {
        address: data.address,
        b_address1: data.billingAddress || "",
        b_address2: "",
        b_city: data.billingCity || "",
        b_country: data.billingCountry || "",
        b_phone: data.billingMobile as string,
        b_state: data.billingProvince || "",
        b_province: data.billingProvince || "",
        b_tel: data.billingTel as string,
        b_zipcode: data.billingPostalCode || "",
        b_firstname: data.firstName,
        b_lastname: data.lastName,
        brithday: data.dob as string,
        car: data.carModel as string,
        city: data.city as string,
        country: data.country as string,
        forename: data.firstName,
        gender: data.gender as string,
        make: data.carMake as string,
        mobile: data.mobile as string,
        sameAsBilling: data.sameAsShipping as boolean,
        state: data.province as string,
        surname: data.lastName,
        tel: data.tel as string,
        year: parseInt(data.carYear as string),
        zipcode: data.postalCode as string,
        new_email: data.changingEmail as string,
        reenter_new_email: data.changingEmail as string,
        email: data.email as string,
        model: data.carModel as string,
        checked: false,
      },
      userID: state.user?.id as number,
    };
  
    // Show toast notification when starting to save
    toast.loading("Saving your profile details...");
  
    mutation.mutate(payload, {
      onSuccess: (data) => {
        dispatch({ type: "SET_USER_INFO", payload: data.userinfo });
        dispatch({ type: "SET_BILLING_INFO", payload: data.billing });
        toast.dismiss();
        toast.success("Profile updated successfully");
      },
      onError: (error) => {
        console.error(error);
        toast.dismiss();
        toast.error("Failed to update profile. Please try again.");
      },
    });
  };

  
  
  

  return (
    <div className=" container my-10 mx-auto px-24">
      <div className="mt-5">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-4">
            <div className="flex justify-between items-center">
              <h1 className="font-bold text-4xl text-[#3D3D3D]">About Me</h1>
            </div>
            <FormField
              name="email"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="email"
                      {...field}
                      placeholder="current@email.com"
                      readOnly
                      className="focus:right-0 focus-visible:ring-0 focus-visible:ring-offset-0 mt-5 h-14 rounded-lg"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="changingEmail"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <CustomInput startIcon={Mail} 
                                 type="email" {...field} 
                                 placeholder="incasechanging@email.com" 
                                 className="h-14 rounded-lg"
                                 />
                  </FormControl>
                  <FormMessage className="mt-1" />
                </FormItem>
              )}
            />
            <PersonalInformation control={form.control} />
            <ShippingInformation control={form.control} watchcountry={watchcountry} setValue={form.setValue} />
            <BillingInformation
              control={form.control}
              watchSameAsShipping={watchSameAsShipping}
              watchbillingCountry={watchbillingCountry}
              setValue={form.setValue}
            />
            <VehicalInformation control={form.control} />

            <Button className="bg-[#FFE512] hover:bg-[#FFE512] text-black px-12 rounded-lg" type="submit">
                Save
              </Button>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default Profile;
