import SectionCard from "@/components/sectionCard";
import { truncate } from "@/lib/utils";
import FilterProduct from "./components/filterProduct";
import { useQuery } from "react-query";
import { QueryKeys, fetchSearchResults, specialsProducts } from "@/lib/queries";
import { useParams, useNavigate, useLocation } from "react-router";
import { IMAGE_URL } from "@/config/appConfig";
import { useContext, useState, useEffect } from "react";
import ProductDetailDialog from "@/components/dialog/productDetailDialog";
import { Product } from "@/interfaces/product.interface";
import { getPriceAgainstLevel } from "@/utils/helpers/getPriceAgainstLevel";
import { AppContext } from "@/context/context";
import { Dealer } from "@/interfaces/dealer.interface";
import { PaginationComponent } from "../specials/components/paginationComponent";
import { Loader } from "lucide-react";

const SingleProductPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [productId, setProductId] = useState<string>("");
  const [showProductDialog, setShowProductDialog] = useState<boolean>(false);
  const [pages, setPages] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [year, setYear] = useState<number>(0);
  const [item, setItem] = useState<string>(""); // Store item from search
  const [specialPrices, setSpecialPrices] = useState<{ [key: string]: { salePrice: number; orgPrice: number } }>({}); // Track special prices

  const product = params.productId;

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const page = query.get("page");
    const yearFilter = query.get("year");
    const itemFilter = query.get("item");

    if (page) {
      setCurrentPage(parseInt(page));
    }

    if (yearFilter) {
      setYear(parseInt(yearFilter));
    }

    if (itemFilter) {
      setItem(itemFilter);
    }
  }, [location.search]);

  const { data, isLoading } = useQuery(
    [QueryKeys.PRODUCT_LIST, product, currentPage, year, item],
    () => fetchSearchResults(product as string, year, item, currentPage),
    {
      refetchOnWindowFocus: false,
      enabled: !!product || currentPage > 1 || !!item,
      onSuccess: (data) => {
        setCurrentPage(data.items.current_page);
        const totalPages = Math.ceil(data.items.total / 20);
        const pages = [...Array(totalPages).keys()].map((i) => i + 1);
        setPages(pages);
      },
    }
  );

  const { state } = useContext(AppContext);
  const dealerUser = state.user && (state.user as Dealer);
  const isDealer = dealerUser && dealerUser.account;

  // Fetch special prices for non-dealers
  useQuery(QueryKeys.SPECIAL, specialsProducts, {
    enabled: !isDealer, // Fetch only for non-dealers
    onSuccess: (data) => {
      const specialsMap = data?.item.reduce((acc, product) => {
        acc[product.item] = {
          salePrice: product.sale_price || 0, // Fallback to 0 to ensure it's a number
          orgPrice: product.org_price || 0,   // Fallback to 0 to ensure it's a number
        };
        return acc;
      }, {} as { [key: string]: { salePrice: number; orgPrice: number } });
      setSpecialPrices(specialsMap);
    },
  });

  // Fetch price based on dealer/non-dealer logic
  const fetchPrice = (product: Product) => {
    if (isDealer) {
      const dealerType = Number(dealerUser?.dealer_info?.pricecode) || 0;
      return getPriceAgainstLevel(dealerType, product);
    } else {
      const specialPriceData = specialPrices[product.item];
      if (specialPriceData?.salePrice) {
        return specialPriceData.orgPrice;
      } else if (product.onsale) {
        return product.pricel; // Apply 10% discount for on-sale items, done below
      } else {
        return product.pricel; // Regular price as fallback
      }
    }
  };

  const handleProductClick = (productCode: string) => {
    setProductId(productCode);
    setShowProductDialog(true);
    navigate(`${location.pathname}?page=${currentPage}&year=${year}&item=${item}`); // Include item in URL
  };

  const handleSearch = (year: number, item: string) => {
    setYear(year);
    setItem(item);
    navigate(`${location.pathname}?page=${currentPage}&year=${year}&item=${item}`); // Trigger search via URL
  };

  return (
    <div className="mx-[8%] my-36 px-4 md:px-8">
      <FilterProduct
        year={year} // Pass selected year
        item={item} // Pass selected item
        handleYearChange={(year) => setYear(year)}
        handleSearch={handleSearch} // Pass search handler
      />

      <p className="text-center italic text-[#3D3D3D] text-sm mb-5 font-black z-10">
        {data && data.items.total} products found
      </p>

      {isLoading && (
        <div className="w-full h-full flex items-center justify-center">
          <Loader className="animate-spin h-16 w-16" />
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-fit mx-auto">
        {data &&
          data.items &&
          data.items.data.length > 0 &&
          data.items.data.map((product, index) => {
            const specialPriceData = specialPrices[product.item];
            const specialPrice = specialPriceData?.salePrice ? `CAD ${specialPriceData.salePrice.toFixed(2)}` : undefined;
            //const originalPrice = specialPriceData?.orgPrice ? `CAD ${specialPriceData.orgPrice.toFixed(2)}` : undefined;

            return (
              <SectionCard
                key={index}
                code={product.item}
                price={`CAD ${fetchPrice(product).toFixed(2)}`}
                promoPrice={!isDealer && product.onsale ? `CAD ${(product.pricel * 0.9).toFixed(2)}` : undefined} 
                specialPrice={specialPrice}
                title={truncate(product.descrip, 20)}
                make={product.make}
                year={`${product.year_from}-${product.year_end}`}
                image={IMAGE_URL + product.img_path}
                imageClassName=""
                className=""
                onClick={() => handleProductClick(product.item)}
              />
            );
          })}
      </div>

      {data && data.items.data.length > 0 && (
        <PaginationComponent currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={pages.length} />
      )}

      {showProductDialog && (
        <ProductDetailDialog
          open={showProductDialog}
          onClose={() => setShowProductDialog(false)}
          productCode={productId}
        />
      )}
    </div>
  );
};

export default SingleProductPage;
