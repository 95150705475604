import { useForm } from "react-hook-form";
import { ForgetPasswordSchema, forgetPasswordSchema } from "./types/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { CustomInput } from "@/components/ui/customInput";
import { Loader, Mail } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useMutation } from "react-query";
import { resetPassword } from "@/lib/queries";
import { useState } from "react";
import { Link } from "react-router-dom";

const ForgetPasswordPage = () => {
  const form = useForm<ForgetPasswordSchema>({
    resolver: zodResolver(forgetPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const [successMessage, setSuccessMessage] = useState<string>("");

  const mutation = useMutation(resetPassword, {
    onSuccess(data) {
      if (data.status === "good") {
        setSuccessMessage(
          "Your request for a new password has been received. Please check your email and click the link sent to you to reset your password. If you do not see our email please check your junk, spam or social folders"
        );
      } else {
        setSuccessMessage("Something went wrong, please try again later");
      }
    },
  });

  const onSubmit = (data: ForgetPasswordSchema) => {
    setSuccessMessage("");
    mutation.mutate(data.email);
  };

  return (
    <div className="container mx-auto mt-44 flex flex-col items-center gap-5 p-5">
      <h1 className="font-bold text-3xl text-center">Forget Password</h1>
      <div className="flex items-center gap-2 flex-col my-2 w-full md:w-1/2 lg:w-2/6">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2 w-full">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <CustomInput
                      placeholder="yourname@email.com"
                      startIcon={Mail}
                      className="h-14 border-[#BEBEBE] rounded-lg"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="mt-1" />
                </FormItem>
              )}
            />
            <div className="pt-5 space-x-2 flex justify-between items-center">
              <Button
                type="submit"
                className="bg-[#FFE512] w-full h-14 hover:bg-[#FFE512] text-[#3D3D3D] font-black"
                disabled={mutation.isLoading}
              >
                {mutation.isLoading && <Loader className="w-4 h-4 animate-spin mr-3" />}
                Reset Password
              </Button>
            </div>
          </form>
        </Form>
        <div className="flex justify-center items-center my-1">
          <p className="text-[#7E7E7E]">
            Already have an account?{" "}
            <Link to="/sign-in" className="underline">
              Sign in
            </Link>
          </p>
        </div>
        {successMessage && (
          <p className="bg-[#dff0d8]  text-[#3c763d] text-sm text-center rounded-md p-2 my-2">{successMessage}</p>
        )}
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
