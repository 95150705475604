import ProductCard from "@/components/productCard";
import { IMAGE_URL } from "@/config/appConfig";
import { QueryKeys, fetchMakes } from "@/lib/queries";
import { useQuery } from "react-query";

const ProductsPage = () => {
  const { data: makes } = useQuery(QueryKeys.MAKES, () => fetchMakes(), {
    refetchOnWindowFocus: false,
  });
  return (
    <div className="container w-fit mx-auto my-48">
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-9 px-4 md:px-8">
        {makes &&
          makes.length > 0 &&
          makes.map((make, index) => (
            <ProductCard
              key={index}
              imageUrl={IMAGE_URL + make.path}
              title={make.make && make.make.replace(/_/g, " ").toUpperCase()}
              href={`/all-products/${make.make}`}
            />
          ))}
      </div>
    </div>
  );
};

export default ProductsPage;
