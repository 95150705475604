import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";

interface CartItem {
  description: string;
  code: string;
  make: string;
  quantity: number;
  price: number;
}

interface OrderTableProps {
  items: CartItem[];
}

const OrderTable: React.FC<OrderTableProps> = ({ items }) => {
  return (
    <Table>
      <TableHeader className="bg-[#3D3D3D] hover:bg-[#3D3D3D]">
        <TableRow className=" hover:bg-[#3D3D3D]">
          <TableHead className="text-white h-10 rounded-l-3xl">Description</TableHead>
          <TableHead className="text-white h-10 text-center">Item</TableHead>
          <TableHead className="text-white h-10 text-center">Make</TableHead>
          <TableHead className="text-white h-10 text-center">Quantity</TableHead>
          <TableHead className="text-white h-10 rounded-r-3xl text-center">Price</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item, index) => (
          <TableRow key={index}>
            <TableCell className="py-2 mx-auto text-start">{item.description}</TableCell>
            <TableCell className="py-2 mx-auto text-center">{item.code}</TableCell>
            <TableCell className="py-2 mx-auto text-center">{item.make}</TableCell>
            <TableCell className="py-2 mx-auto text-center">{item.quantity}</TableCell>
            <TableCell className="py-2 mx-auto text-center">{"$" + (item.price).toFixed(2) + " CAD"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default OrderTable;
