import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { SubmitInquiryType, submitInquirySchema } from "../types/Schema";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { useMutation } from "react-query";
import { submitInquiry } from "@/lib/queries";
import toast from "react-hot-toast";
import { useContext } from "react";
import { AppContext } from "@/context/context";
import { User } from "@/interfaces/user.interface";
import { Dealer } from "@/interfaces/dealer.interface";

const SubmitInquiry = () => {
  const { state } = useContext(AppContext);

  const user = state.user as User;
  const dealerUser = state.user as Dealer;

  const form = useForm<SubmitInquiryType>({
    resolver: zodResolver(submitInquirySchema),
    defaultValues: {
      subject: "",
      message: "",
    },
  });

  const mutation = useMutation(submitInquiry, {
    onSuccess(data) {
      if (data.status) {
        toast.success("Thank you for your email, GLA will contact you as soon as possible.");
        form.reset({
          subject: "",
          message: "",
        });
      }
    },
  });

  const onSubmit = (data: SubmitInquiryType) => {
    const custUser = user?.firstname + " " + user?.lastname + ", " + user?.email;
    const dealUser = dealerUser.account + ", " + dealerUser.email;
    mutation.mutate({
      custno: dealerUser.account ? dealerUser.account : user.id,
      message: data.message,
      subject: data.subject,
      type: dealerUser.account ? "dealer" : "customer",
      user: dealerUser.account ? dealUser : custUser,
    });
  };
  return (
    <div className="container mx-auto px-4 my-10">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-3 mt-5">
          <div className="flex justify-between items-center">
            <h1 className="font-bold text-4xl text-[#3D3D3D]">Submit Inquiry</h1>
            <Button className="bg-[#FFE512] hover:bg-[#FFE512] text-black px-12" type="submit">
              Send
            </Button>
          </div>
          <FormField
            name="subject"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger className=" border border-[#000000] w-[150px]">
                      <SelectValue placeholder="*Subject" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Parts Inquiry">Parts Inquiry</SelectItem>
                      <SelectItem value="Shipping Inquiry">Shipping Inquiry</SelectItem>
                      <SelectItem value="Sales Inquiry">Sales Inquiry</SelectItem>
                      <SelectItem value="Other Inquiry">Other Inquiry</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
          <FormField
            name="message"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Textarea {...field} className="w-full h-52 border-[#000000] p-3 mt-6" placeholder="*Write your messages" />
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </div>
  );
};

export default SubmitInquiry;
