import { AppContext } from "@/context/context";
import { Dealer } from "@/interfaces/dealer.interface";
import { QueryKeys, dealerOrderHistory } from "@/lib/queries";
import { useContext } from "react";
import { useQuery } from "react-query";

const DealerProfile = () => {
  const { state } = useContext(AppContext);
  const user = state.user as Dealer;

  const { data } = useQuery(QueryKeys.ORDER_HISTORY, () => dealerOrderHistory(user.account), {
    enabled: !!user.account,
    refetchOnWindowFocus: false,
  });

  // Function to format date as yyyy-mm-dd
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  // Get the most recent order from history
  const recentOrder = data?.history?.[0];
  const recentOrderDetails = recentOrder
    ? `${recentOrder.order_num}, ${formatDate(recentOrder.date_order)}`
    : '---';

  return (
    <div className="my-10">
      <div className="mt-5">
        <h2 className="font-bold text-4xl text-[#3D3D3D]">Dealer Profile</h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
        <div className="shadow-md bg-white rounded-md p-5">
          <h5 className="text-xl font-semibold">Account Details</h5>
          <hr />
          <div className="flex flex-col space-y-3 mt-5">
            <div>
              <span className="text-gray-500">Account:</span> <span>{user.account}</span>
            </div>
            <div>
              <span className="text-gray-500">Company:</span> <span>{user.name}</span>
            </div>
            <div className="flex items-start">
              <span className="text-gray-500 mr-3">Address:</span>
              <div className="flex flex-col">
                <p>{user.dealer_info.address1}</p>
                <p>{user.dealer_info.city}, {user.dealer_info.state}</p>
                <p>{user.dealer_info.country}</p>
                <p>{user.dealer_info.zip}</p>
              </div>
            </div>
            <div className="flex gap-3 items-center">
              <span className="text-gray-500">Email:</span> <span>{user.email}</span>
            </div>
          </div>
        </div>
        <div className="shadow-md bg-white rounded-md p-5">
          <h5 className="text-xl font-semibold">Order Status</h5>
          <hr />
          <div className="flex flex-col space-y-3 mt-5">
            <div>
              <span className="text-gray-500">Recent Order:</span> <span>{recentOrderDetails}</span>
            </div>
            <div>
              <span className="text-gray-500">Order History:</span> <span>{(data && data.history && data.history.length) || "---"}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerProfile;
