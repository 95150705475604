import { Dialog, DialogContent } from "../ui/dialog";
import ProductDetail from "@/pages/products/productDetail";

interface ProductDetailDialogProps {
  open: boolean;
  onClose: () => void;
  productCode: string;
}

const ProductDetailDialog = ({ open, onClose, productCode }: ProductDetailDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        // OG sizing sm:max-w-5xl max-h-[calc(100vh-64px)]
        className="sm:max-w-7xl h-[60rem] overflow-y-auto no-scrollbar"
        overlayClassName="bg-neutral-600/50"
      >
        <ProductDetail productCode={productCode} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default ProductDetailDialog;
