

interface OrderSummaryProps {
  subtotal: number;
  shipping: number;
  tax: number;
  total: number;

}

const OrderSummary: React.FC<OrderSummaryProps> = ({ subtotal, shipping, tax, total}) => {
  return (
    <div>
      <div className="bg-[#3D3D3D] text-white rounded-[31px] h-10 flex justify-center items-center">
        <h2 className="text-lg font-semibold">Order Summary</h2>
      </div>
      <div className="p-4 mx-4 space-y-2 shadow-md shadow-gray-400">
        <div className="flex justify-between">
          <span className="text-gray-600">Subtotal:</span>
          <span className="font-medium text-gray-900">{subtotal.toFixed(2)} CAD</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">Shipping:</span>
          <span className="font-medium text-gray-900">{shipping.toFixed(2)} CAD</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">Tax:</span>
          <span className="font-medium text-gray-900">{tax.toFixed(2)} CAD</span>
        </div>
        <div className="border-[#3D3D3D] my-4 border-t-2"></div>
        <div className="flex justify-between">
          <span className="text-lg text-gray-900">Total:</span>
          <span className="text-lg font-bold text-gray-900">{total.toFixed(2)} CAD</span>
        </div>
      </div>
      <div className="flex justify-center my-3">
        <div className="flex items-center space-x-2">

        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
