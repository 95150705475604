import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { AppContext } from "@/context/context";
import { Dealer } from "@/interfaces/dealer.interface";
import { QueryKeys, dealerOrderHistory } from "@/lib/queries";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

const ITEMS_PER_PAGE = 10;

const PendingOrder = () => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();

  const user = state.user as Dealer;

  const { data } = useQuery(QueryKeys.ORDER_HISTORY, () => dealerOrderHistory(user.account), {
    enabled: !!user.account,
    refetchOnWindowFocus: false,
  });

  const [currentPage, setCurrentPage] = useState(1);

  const handleRowClick = (orderNumber: string) => {
    navigate(`/pending-order-details?so=${orderNumber}&id=${user.id}`);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = data ? Math.ceil(data.pending.length / ITEMS_PER_PAGE) : 1;
  const paginatedOrders = data ? data.pending.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE) : [];

  // Determine if pagination buttons should be disabled
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;
  const hasPendingOrders = data && data.pending.length > 0;

  return (
    <div className="container mx-auto px-4 my-10">
      <h1 className="font-bold text-4xl text-[#3D3D3D]">Pending Order</h1>
      <div className="mt-10">
        <Table>
          <TableHeader className="bg-[#3D3D3D] hover:bg-[#3D3D3D]">
            <TableRow className="hover:bg-[#3D3D3D]">
              <TableHead className="text-white rounded-l-3xl text-center w-1/5">Order Date</TableHead>
              <TableHead className="text-white text-center w-1/5">Order Number</TableHead>
              <TableHead className="text-white text-center w-1/5">Pending Status</TableHead>
              <TableHead className="text-white text-center w-1/5">Track Order</TableHead>
              <TableHead className="text-white rounded-r-3xl text-center w-1/5">Subtotal</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedOrders.length > 0 ? (
              paginatedOrders.map((order, index) => (
                <TableRow
                  key={index}
                  className="cursor-pointer"
                  onClick={() => handleRowClick(order.order_num)}
                >
                  <TableCell className="text-center w-1/5">{format(order.date_order, "yyyy-MM-dd")}</TableCell>
                  <TableCell className="text-center w-1/5">{order.order_num}</TableCell>
                  <TableCell className="text-center w-1/5">
                    {order.sales_status === 3 ? "Pending for Quote" : order.sales_status === 5 ? "Pending for Reply" : ""}
                  </TableCell>
                  <TableCell className="text-center w-1/5">{order.track_num || "/"}</TableCell>
                  <TableCell className="text-center w-1/5">{order.subtotal.toFixed(2)} CAD</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} className="text-center">
                  No pending orders
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="flex justify-between mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={isFirstPage || !hasPendingOrders}
            className="bg-[#FFE512] px-4 py-2 rounded-lg"
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={isLastPage || !hasPendingOrders}
            className="bg-[#FFE512] px-4 py-2 rounded-lg"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default PendingOrder;
