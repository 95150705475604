import ProductDetailDialog from "@/components/dialog/productDetailDialog";
import SectionCard from "@/components/sectionCard";
import { Button } from "@/components/ui/button";
import { IMAGE_URL } from "@/config/appConfig";
import { AppContext } from "@/context/context";
import { Dealer } from "@/interfaces/dealer.interface";
import { Product } from "@/interfaces/product.interface";
import {
  QueryKeys,
  fetchDealerWishlistItems,
  fetchWishlistItems,
  removeItemFromWishlist,
  removeItemFromWishlistDealer,
} from "@/lib/queries";
import { truncate } from "@/lib/utils";
import { getPriceAgainstLevel } from "@/utils/helpers/getPriceAgainstLevel";
import { Loader } from "lucide-react";
import { useContext, useState } from "react";
import { toast} from 'react-toastify';
//import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";

const WishlistPage = () => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);

  const dealerUser = state.user && (state.user as Dealer);
  const isDealer = dealerUser && dealerUser.account;

  const [items, setItems] = useState<Product[]>([]);
  const [productId, setProductId] = useState<string>("");
  const [showProductDialog, setShowProductDialog] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { isLoading } = useQuery(QueryKeys.WISHLIST, () => fetchWishlistItems(state.user?.id as number), {
    enabled: isDealer ? false : true,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setItems(data.items);
    },
  });

  const { isLoading: dealerWishlishLoading } = useQuery(
    "dealer-wishlist",
    () => fetchDealerWishlistItems(state.user?.id as number),
    {
      enabled: isDealer ? true : false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setItems(data.items);
      },
    }
  );

  const mutation = useMutation(removeItemFromWishlist, {
    onSuccess: (data) => {
      if (data === 1) {
        queryClient.invalidateQueries(QueryKeys.WISHLIST);
        toast.success("Item removed from wishlist");
      }
    },
  });

  const dealerMutation = useMutation(removeItemFromWishlistDealer, {});

  const handleRemoveItem = (item: string) => {
    if (isDealer) {
      dealerMutation.mutate({ user: dealerUser.id as number, item });
      return;
    }
    mutation.mutate({ user: (state && state.user && (state.user?.id as number)) || 0, item });
    queryClient.invalidateQueries(QueryKeys.WISHLIST);
    queryClient.invalidateQueries("dealer-wishlist");
    if (isDealer){
      toast.success("Item removed from wishlist");
    }
  };

  const fetchPrice = (product: Product) => {
    const priceLevel = getPriceAgainstLevel((dealerUser && (dealerUser.level as number)) || 0, product);
    return isDealer ? priceLevel : product.pricel;
  };

  return (
    <div className=" container mx-auto my-20 mt-40 px-8">
      <div className="flex justify-center items-center flex-col gap-5">
        <Button className="bg-[#FFE512] hover:bg-[#FFE512] text-black rounded-lg px-8" onClick={() => navigate("/")}>
          Return to Home
        </Button>
      </div>
      <h1 className="text-center font-black text-[#3D3D3D] text-3xl my-8">Wishlist</h1>
      {(isLoading || dealerWishlishLoading) && (
        <div className="w-full h-full flex items-center justify-center my-5">
          <Loader className="animate-spin  h-16 w-16" />
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-10 content-fit">
        {items &&
          items.length > 0 &&
          items.map((item, index) => (
            <SectionCard
              key={index}
              code={item.item}
              price={`CAD ${fetchPrice(item).toFixed(2)}`}
              title={truncate(item.descrip, 20)}
              make={item.make}
              year={item.year_from + "-" + item.year_end}
              image={IMAGE_URL + item.img_path}
              imageClassName=""
              className=""
              showCross
              onCrossClick={handleRemoveItem}
              disabled={item.onhand === 0}
              onClick={() => {
                setProductId(item.item);
                setShowProductDialog(true);
              }}
            />
          ))}
      </div>

      {showProductDialog && (
        <ProductDetailDialog
          open={showProductDialog}
          onClose={() => setShowProductDialog(false)}
          productCode={productId}
        />
      )}
    </div>
  );
};

export default WishlistPage;
