import { Card } from "@/components/ui/card";
import { IMAGE_URL } from "@/config/appConfig";
import { QueryKeys, fetchCatalogs } from "@/lib/queries";
import { cn } from "@/lib/utils";
import { Loader } from "lucide-react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

const CatalogPage = () => {
  const { data: catalogs, isLoading } = useQuery(QueryKeys.CATALOG, () => fetchCatalogs(), {
    refetchOnWindowFocus: false,
  });

  return (
    <div className="container px-4 md:px-8 mt-60 mb-36 mx-auto">
      <h1 className="text-center text-xl font-black">Check out our catalog on different models.</h1>
      <p className="text-center text-lg text-[#3D3D3D] font-light">Read it online or download it.</p>
      {isLoading && (
        <div className="w-full h-full flex items-center justify-center my-5">
          <Loader className="animate-spin  h-16 w-16" />
        </div>
      )}
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-4 px-4 md:px-8 mt-5 w-fit">
        {catalogs &&
          catalogs.catalogs.length > 0 &&
          catalogs.catalogs.map((catalog, index) => (
            <Card className="shadow-md shadow-gray-400 p-4 flex flex-col items-center justify-start relative rounded-lg m-2.5" key={index}>
              <img src={IMAGE_URL + "/images/catalog/" + catalog.path} alt="product" className={cn("content-fit h-20 mt-2")} />
              <p className="text-center text-md mt-6 font-bold text-[#3D3D3D] uppercase ">{catalog.display}</p>
              <Link className="absolute inset-0" to={`${IMAGE_URL}/images/PDF/${catalog.name}.pdf`} target="_blank">
                <span className="sr-only">View Product</span>
              </Link>
            </Card>
          ))}
      </div>
    </div>
  );
};

export default CatalogPage;
