import { useForm } from "react-hook-form";
import { SignUpSchema, signUpSchema } from "./types/Schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { CustomInput } from "@/components/ui/customInput";
import { Mail } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { useMutation } from "react-query";
import { signUp } from "@/lib/queries";
import { useContext, useEffect } from "react";
import { AppContext } from "@/context/context";

const SignUpPage = () => {
  const { dispatch, state } = useContext(AppContext);
  const navigate = useNavigate();

  const form = useForm<SignUpSchema>({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      recieveUpdates: false,
    },
  });

  const mutation = useMutation(signUp, {
    onSuccess: (data) => {
      dispatch({ type: "SET_USER", payload: data.user });
      navigate("/customer-info");
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const onSubmit = (data: SignUpSchema) => {
    const payload = {
      firstname: data.firstName,
      lastname: data.lastName,
      email: data.email,
      password: data.password,
      checked: data.recieveUpdates || false,
    };
    mutation.mutate(payload);
  };

  useEffect(() => {
    if (state.user) {
      navigate("/profile");
    }
  }, [state.user, navigate]);

  return (
    <div className="container mx-auto mt-44 mb-20 flex flex-col items-center gap-5 p-5">
      <h1 className="font-bold text-3xl text-center">Welcome to Golden Leaf Automotive!</h1>
      <p className="font-light text-center max-w-5xl mx-auto">
        Your premier destination for vintage car enthusiasts! Explore our extensive collection of rare and authentic
        vintage car parts and bodies. Whether you are restoring a classic beauty or adding unique touches to your
        project, we are here to provide you with top-quality components to bring your vision to life. Sign in to access
        our exclusive offerings and embark on your journey into automotive history with Golden Leaf Automotive.{" "}
      </p>
      <div className="flex items-center gap-2 flex-col my-2 w-full md:w-3/4 lg:w-5/12">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 w-full">
            <div className="grid grid-cols-2 gap-4">
              <FormField
                name="firstName"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder="First Name" {...field} className="border-[#BEBEBE] h-14 rounded-lg" />
                    </FormControl>
                    <FormMessage className="mt-1" />
                  </FormItem>
                )}
              />
              <FormField
                name="lastName"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder="Last Name" {...field} className="border-[#BEBEBE] h-14 rounded-lg" />
                    </FormControl>
                    <FormMessage className="mt-1" />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              name="email"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <CustomInput
                      placeholder="yourname@email.com"
                      startIcon={Mail}
                      className="border-[#BEBEBE] h-14 rounded-lg"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="mt-1" />
                </FormItem>
              )}
            />
            <FormField
              name="password"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <CustomInput
                      placeholder="yourpassword"
                      type="password"
                      className="border-[#BEBEBE] h-14 rounded-lg"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="mt-1" />
                </FormItem>
              )}
            />
            <FormField
              name="confirmPassword"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <CustomInput
                      placeholder="reconfirmyourpassword"
                      type="password"
                      className="border-[#BEBEBE] h-14 rounded-lg"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="mt-1" />
                </FormItem>
              )}
            />
            <p className="italic font-normal text-center my-2 text-[#7E7E7E] text-sm">
              Already have an account?{" "}
              <Link to="/sign-in" className="underline ml-3">
                Sign in
              </Link>
            </p>
            <div className="flex justify-center">
              <FormField
                name="recieveUpdates"
                control={form.control}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-2 space-y-0 mt-5">
                    <FormControl>
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <div className="space-y-1 leading-none text-[#7E7E7E]">
                      <FormLabel>I agree to receive updates on offers, promotions and deals</FormLabel>
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div className="flex justify-center">
              <Button variant={"outline"} type="submit" className="text-lg w-[56%] h-16 rounded-lg border-[#3D3D3D] mt-1">
                Register and join now
              </Button>
            </div>
            <p className="italic text-sm text-center w-[60%] mx-auto">
              By registering you agree to the terms and conditions of <br></br> Golden Leaf Automotive website
            </p>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default SignUpPage;
