import { Control } from "react-hook-form";
import { CustomerInfoType } from "../types/Schema";
import SectionDivider from "@/components/sectionDivider";
import { FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { getYearList } from "@/utils/helpers/getYearList";
import { useQuery } from "react-query";
import { QueryKeys, fetchMakes } from "@/lib/queries";

interface VehicleInformationProps {
  control: Control<CustomerInfoType>;
}

const VehicalInformation = ({ control }: VehicleInformationProps) => {
  const yearList = getYearList(1949);

  const { data: makes } = useQuery(QueryKeys.MAKES, () => fetchMakes(), {
    refetchOnWindowFocus: false,
  });

  return (
    <div className="space-y-4 pt-2">
      <div className="flex justify-center">
      <SectionDivider title="Vehical Information" />
      </div>
      <FormField
        name="carModel"
        control={control}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input placeholder="Car Model" {...field} className="border-[#BEBEBE] h-14 rounded-lg" />
            </FormControl>
            <FormMessage className="mt-1" />
          </FormItem>
        )}
      />
      <div className="grid grid-cols-3 gap-3">
        <FormField
          name="carYear"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Select onValueChange={field.onChange} value={field.value?.toString()}>
                  <SelectTrigger className="border-[#BEBEBE] h-14 rounded-lg">
                    <SelectValue placeholder="Year" className="text-[#BEBEBE] h-14 rounded-lg" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {yearList.map((year) => (
                        <SelectItem key={year} value={year.toString()}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage className="mt-1" />
            </FormItem>
          )}
        />
        <FormField
          name="carMake"
          control={control}
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormControl>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger className="border-[#BEBEBE] h-14 rounded-lg">
                    <SelectValue placeholder="Make" className="text-[#BEBEBE] h-14 rounded-lg" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {makes &&
                        makes.length > 0 &&
                        makes?.map((make) => (
                          <SelectItem key={make.id} value={make.make}>
                            {make.make}
                          </SelectItem>
                        ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage className="mt-1" />
            </FormItem>
          )}
        />
        
      </div>
      <div className="h-6"></div>
    </div>
  );
};

export default VehicalInformation;
