import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { OneOrderProduct } from "@/interfaces/product.interface"; // Import Product interface

interface OrderTableProps {
  items: OneOrderProduct[];
}

const OrderTable: React.FC<OrderTableProps> = ({ items }) => {
  return (
    <Table>
      <TableHeader className="bg-[#3D3D3D] hover:bg-[#3D3D3D]">
        <TableRow className=" hover:bg-[#3D3D3D]">
          <TableHead className="text-white h-10 rounded-l-3xl text-lg font-semibold">Description</TableHead>
          <TableHead className="text-white h-10 text-center text-lg font-semibold">Item</TableHead>
          <TableHead className="text-white h-10 text-center text-lg font-semibold">Make</TableHead>
          <TableHead className="text-white h-10 text-center text-lg font-semibold">Quantity</TableHead>
          <TableHead className="text-white h-10 rounded-r-3xl text-center text-lg font-semibold">Price</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item, index) => (
          <TableRow key={index}>
            <TableCell className="py-2 mx-auto text-start">{item.descrip}</TableCell>
            <TableCell className="py-2 mx-auto text-center">{item.item}</TableCell>
            <TableCell className="py-2 mx-auto text-center">{item.make}</TableCell>
            <TableCell className="py-2 mx-auto text-center">{item.qty}</TableCell>
            <TableCell className="py-2 mx-auto text-center">{item.price.toFixed(2)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default OrderTable;
