// HomePage.tsx
import Banner from "./components/banner";
import Cougar from "./components/cougar";
import HotSaleProducts from "./components/hotSale";
import Promotion from "./components/promotion";
import CookieDisclaimer from './components/CookieDisclaimer'; // Import the component

const HomePage = () => {
  return (
    <div className="container mx-auto my-16 px-4 md:px-8">
      <Banner />
      <Cougar />
      <Promotion />
      <HotSaleProducts />
      <CookieDisclaimer />
    </div>
    
  );
};

export default HomePage;
