import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ChangePasswordType, changePasswordSchema } from "../types/Schema";
import { CustomInput } from "@/components/ui/customInput";
import { useMutation } from "react-query";
import { changePassword } from "@/lib/queries";
import { toast } from "react-toastify"; // Correct import for react-toastify
import { useContext } from "react";
import { AppContext } from "@/context/context";

const ChangePassword = () => {
  const { state } = useContext(AppContext);

  const form = useForm<ChangePasswordType>({
    resolver: zodResolver(changePasswordSchema),
    defaultValues: {
      confirmPassword: "",
      currentPassword: "",
      newPassword: "",
    },
  });

  const mutation = useMutation(changePassword, {
    onSuccess(data) {
      if (data.status === "fail") {
        toast.error("Failed to change password, is your current password correctly?");
      } else {
        toast.success("Password changed successfully");
        form.reset({
          confirmPassword: "",
          currentPassword: "",
          newPassword: "",
        });
      }
    },
  });

  const onSubmit = (data: ChangePasswordType) => {
    mutation.mutate({
      data: {
        oldPass: data.currentPassword,
        newPass: data.newPassword,
        confirmPass: data.confirmPassword,
      },
      userID: state.user?.id as number,
    });
  };

  return (
    <div className="container mx-auto my-10 px-4">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-4 mt-5">
          <div className="flex justify-between items-center">
            <h1 className="font-bold text-4xl text-[#3D3D3D]">Change Password</h1>
            <Button className="bg-[#FFE512] hover:bg-[#FFE512] text-black px-12" type="submit">
              Save
            </Button>
          </div>
          <FormField
            name="currentPassword"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CustomInput
                    placeholder="currentpassword"
                    type="password"
                    className="border-[#BEBEBE] h-14 rounded-lg"
                    {...field}
                  />
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
          <FormField
            name="newPassword"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CustomInput placeholder="newpassword" type="password" className="border-[#BEBEBE] h-14 rounded-lg" {...field} />
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
          <FormField
            name="confirmPassword"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CustomInput
                    placeholder="confirmpassword"
                    type="password"
                    className="border-[#BEBEBE] h-14 rounded-lg"
                    {...field}
                  />
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </div>
  );
};

export default ChangePassword;
