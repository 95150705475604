// components/CookieDisclaimer.tsx
import React, { useState, useEffect } from 'react';

const CookieDisclaimer: React.FC = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const hasSeenDisclaimer = sessionStorage.getItem('cookieDisclaimerSeen');
    if (!hasSeenDisclaimer) {
      setVisible(true);
      sessionStorage.setItem('cookieDisclaimerSeen', 'true');
    } else {
      setVisible(false);
    }
  }, []);

  const handleClose = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="fixed bottom-4 right-4 bg-white border border-gray-300 p-4 shadow-lg rounded-lg z-50 w-[350px]">
      <button
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-xl"
        onClick={handleClose}
      >
        &times;
      </button>
      <p className='text-sm font-black mb-2'>Why We Use Cookies</p>
      <p className="text-sm">
        This site uses cookies to make your browsing experience more convenient and personal.
        Cookies store useful information on your computer to help us improve the efficiency and relevance of our site for you.<br />
        In some cases, they are essential to making the site work properly.
        By accessing this site, you consent to the use of cookies.
        For more information, refer to GLA's privacy policy and cookie policy.
      </p>
      <button
        className="mt-4 px-4 py-2 bg-[#FFE512] text-black text-sm rounded-lg"
        onClick={handleClose}
      >
        I understand
      </button>
    </div>
  );
};

export default CookieDisclaimer;
