import { Button } from "@/components/ui/button";
import { QueryKeys, contactUs, fetchItem } from "@/lib/queries";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { ShippingInquiry, ShippingInquirySchema } from "./types/Schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { toast } from 'react-toastify';
import { Loader } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { ClassicBodyModelItem, Product } from "@/interfaces/product.interface";
import { useState } from "react";

const ShippingInquiryPage = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const inquiryType = searchParams.get("inquiry") as "shipping" | "eta";

  const productId = params.productId;
  const [product, setProduct] = useState<Product | ClassicBodyModelItem>();

  useQuery([QueryKeys.ITEM], () => fetchItem(productId as string), {
    enabled: !!productId,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setProduct(data.singleItem);
    },
  });

  const form = useForm<ShippingInquiry>({
    resolver: zodResolver(ShippingInquirySchema),
    defaultValues: {
      name: "",
      email: "",
      message: "",
    },
  });

  const mutation = useMutation(contactUs, {
    onSuccess(data) {
      if (data.status) {
        toast.success("Message sent successfully");
        form.reset();
      } else {
        toast.error("Message failed to send");
      }
    },
  });

  const onSubmit = (data: ShippingInquiry) => {
    const subject = inquiryType === "shipping" ? "Shipping Inquiry" : "ETA Inquiry";
    mutation.mutate({
      name: data.name,
      message: `Hello, I want to inquire about item ${product && product.item}. ${data.message}`,
      email: data.email,
      subject: subject,
    });
  };

  const handleBackToItems = () => {
    navigate(-1);
  };

  return (
    <div className="container mx-auto mt-20 md:mt-44 px-4 md:px-60 my-10 md:my-20">
      <div className="flex justify-center items-center flex-col gap-5">
        <Button
          className="bg-[#FFE512] hover:bg-[#FFE512] text-black font-bold px-8 md:px-12 py-4 md:py-6"
          onClick={handleBackToItems}
        >
          Back to Items
        </Button>
      </div>
      <p className="text-center my-4 md:my-5 text-sm md:text-base px-4 md:px-0">
        Please enter all necessary fields in the form below, and click on "Submit" to send your inquiry. If you would
        like to request our Catalog CD, please provide us your Mailing Address in the content box.
      </p>
      <div className="mb-4 space-y-2 md:space-y-4 text-sm md:text-base text-center md:text-left px-4 md:px-0">
        <p>
          <strong>Item: {product && product.item}</strong>
        </p>
        <p>
          <strong>Make: {product && product.make}</strong>
        </p>
        <p>
          <strong>
            Year: {product && product.year_from} -- {product && product.year_end}
          </strong>
        </p>
      </div>
      <h2 className="text-center font-bold text-xl md:text-2xl mb-4 md:mb-6">
        {inquiryType === "shipping" ? "Shipping Inquiry" : "ETA Inquiry"}
      </h2>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 px-4 md:px-0">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name *</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Name" className="h-12 md:h-14 rounded-lg" />
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email *</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Email" className="h-12 md:h-14 rounded-lg" />
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="message"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Message *</FormLabel>
                <FormControl>
                  <Textarea {...field} placeholder="Message" rows={4} className="h-40 md:h-52 rounded-lg" />
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            disabled={mutation.isLoading}
            className="bg-[#FFE512] hover:bg-[#FFE512] text-black font-bold w-full md:w-auto px-10 py-4 md:py-6"
          >
            {mutation.isLoading && <Loader className="mr-2 animate-spin" size={20} />}
            Send Message
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default ShippingInquiryPage;
