import { Form } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { ConfirmOrder, ConfirmOrderSchema } from "./types/Schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router";
import OrderTable from "./components/OrderTable";
import ShippingForm from "./components/ShippingForm";
import OrderSummary from "./components/OrderSummary";
import { useQuery } from "react-query";
import { QueryKeys, getCheckoutItems, getTaxRate, deleteShippingAddress, newShippingAddress, changeShippingAddress, getQuote } from "@/lib/queries"; // Import deleteShippingAddress
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/context";
import { AddressBook } from "@/interfaces/user.interface"; 
import Loader from "@/components/Loader";
import PaymentOption from "@/assets/images/paymentmethod.png";

import { AlertDialog, AlertDialogAction, AlertDialogCancel, 
  AlertDialogContent, AlertDialogDescription, AlertDialogFooter, 
  AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";

import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";  // Import Select components

const ConfirmOrderPage = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [taxRate, setTaxRate] = useState(0);
  const [isModified, setIsModified] = useState(false); // State to track form modification
  const [selectedAddress, setSelectedAddress] = useState<any>(null); // State to track selected address
  const [addressBook, setAddressBook] = useState<AddressBook[]>([]); // State to store the address book
  const [shipping, setShipping] = useState<number>(0);

  const { data, isLoading } = useQuery(QueryKeys.CART, () => getCheckoutItems(state.user?.id as number), {
    enabled: !!state.user,
    refetchOnWindowFocus: false,
  });

  const form = useForm<ConfirmOrder>({
    resolver: zodResolver(ConfirmOrderSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      tel: "",
      notes: "",
      storePickup: false,
    },
  });

  const { setValue, watch, control, reset } = form;
  const storePickup = watch("storePickup");
  const watchProvince = watch("province");
  const watchCountry = watch("country");

  useEffect(() => {
    if (data?.addressBook) {
      setAddressBook(data.addressBook);
    }
  }, [data?.addressBook]);

  useEffect(() => {
    if (data?.total_shipping){
      setShipping(data.total_shipping);
    }
  }, [data?.total_shipping]);

  // Watch for form changes to enable "New" button
  useEffect(() => {
    const subscription = form.watch((value) => {
      // Check if all required fields are filled to consider the form modified
      const allFieldsFilled = !!value.firstName && !!value.lastName && !!value.address && !!value.city && !!value.province && !!value.postalCode && !!value.country && !!value.tel;
      setIsModified(allFieldsFilled);
    });
    return () => subscription.unsubscribe();
  }, [form]);

  const updateTaxRate = async (province: string) => {
    const taxData = await getTaxRate(province);
    setTaxRate(taxData.taxRate);
  };

  useEffect(() => {
    const fetchTaxRate = async (province: string) => {
      await updateTaxRate(province);
    };

    if (storePickup) {
      setValue("firstName", "GOLDEN LEAF");
      setValue("lastName", "AUTOMOTIVE");
      setValue("address", "170 ZENWAY BLVD UNIT#2");
      setValue("city", "WOODBRIDGE");
      setValue("province", "ON");
      setValue("postalCode", "L4H2Y7");
      setValue("tel", "(905)-850-3433");
      setValue("notes", "[Pickup in store]");
      setIsModified(false);
      fetchTaxRate("ON");

      // Clear the selected address and disable related actions
      setSelectedAddress(null);
    } else if (data?.userInfo) {
      const province = data.userInfo.m_state;
      setValue("firstName", data.userInfo.m_forename);
      setValue("lastName", data.userInfo.m_surname);
      setValue("address", data.userInfo.m_address);
      setValue("city", data.userInfo.m_city);
      setValue("province", province);
      setValue("postalCode", data.userInfo.m_zipcode);
      setValue("country", data.userInfo.m_country);
      setValue("tel", data.userInfo.m_tel);
      setValue("notes", "");
      setIsModified(false);
      fetchTaxRate(province);
    }
  }, [storePickup, data, setValue]);

  useEffect(() => {
    if (!storePickup && watchProvince) {
      updateTaxRate(watchProvince);
    }
  }, [watchProvince, storePickup]);

  useEffect(() => {
    // If storePickup is checked, set shipping to 0
    if (storePickup) {
      setShipping(0);  // Set shipping to 0 when "In store pickup" is selected
    } else {
      // Restore shipping from data if not picking up from the store
      setShipping(data?.total_shipping || 0);
    }
  }, [storePickup, data?.total_shipping]); // Run this effect when storePickup or total_shipping changes
  

  useEffect(() => {
    const updateProvince = (country: string) => {
      if (country === "CA") {
        setValue("province", "ON"); // Example: Automatically set to Ontario
      } else if (country === "USA") {
        setValue("province", "NY"); // Example: Automatically set to New York
      }
    };

    updateProvince(watchCountry);
  }, [watchCountry, setValue]);

  const onSubmit = async (formData: ConfirmOrder) => {
    const subtotal = data?.subtotal || 0;
    const tax = (subtotal+shipping) * taxRate;
    
    // Determine the addressID based on conditions
    let addressID: number;
    if (storePickup) {
      addressID = 1; // Address ID for store pickup
    } else if (!selectedAddress) {
      addressID = 0; // No address selected, use default address
    } else {
      addressID = selectedAddress.id; // Address selected from address book
    }
  
    // Create the order object
    const order = {
      ...formData,
      subtotal,
      tax,
      shipping,
      total: subtotal + tax + shipping,
      items: data?.carts || [],
      storePickup: storePickup ? 1 : 0,
      addressID, // Add addressID to the payload
    };
  
    if (data?.oversize && !storePickup) {
      const userId = state.user?.id; // Fetch the user ID from the state
      const payload = {
        id: Number(userId),
        addressID: addressID,
        subtotal: String(subtotal),
        hst: String(tax),
        notes: formData.notes,
      };
      
      // Get the quote data
      const quoteData = await getQuote(payload);
      console.log("sono", quoteData.sono)
  
      // Dispatch only the sono from quoteData to the order-quote page
      dispatch({
        type: "SET_ORDER",
        payload: {
          sono: quoteData.sono,
        },
      });
  
      navigate("/order-quote");
    } else {
      dispatch({
        type: "SET_ORDER",
        payload: order,
      });
  
      navigate("/payment");
    }
  };
  
  

  const cartItems = data?.carts.map((item: any) => ({
    description: item.descrip,
    code: item.item,
    make: item.make,
    quantity: item.qty,
    price: item.price,
  })) || [];

  const watchcountry = form.watch("country") as "CA" | "USA";

  const subtotal = data?.subtotal || 0;
  const tax = (subtotal + shipping) * taxRate;
  const total = subtotal + shipping + tax;

  // Check if the required fields are loaded
  const requiredFieldsLoaded = !!watch("province");

  if (isLoading || !requiredFieldsLoaded) {
    return <Loader />;
  }

  // Function to handle address selection
  const handleAddressSelect = async (address: any) => {
    setSelectedAddress(address);  // Update selected address in state
  
    try {
      // Call the changeShippingAddress API with the selected address ID
      const response = await changeShippingAddress({ id: address.id });
  
      // Destructure the response to get the updated order information
      console.log(response);
  
      // Update form fields with the new address information
      setValue("firstName", address.forename);
      setValue("lastName", address.surname);
      setValue("address", address.address);
      setValue("city", address.city);
      setValue("province", address.state);
      setValue("postalCode", address.zipcode);
      setValue("country", address.country);
      setValue("tel", address.tel);
      setShipping(response.total_shipping);
  
  
      setIsModified(false);
    } catch (error) {
      console.error("Failed to update the shipping address:", error);
    }
  };

  // Function to clear the form fields except country and province
  const handleClear = () => {
    const currentCountry = watch("country");
    const currentProvince = watch("province");
  
    reset({
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      postalCode: "",
      tel: "",
      notes: "",
      storePickup: false,
      country: currentCountry,  // Preserve country
      province: currentProvince, // Preserve province
    });
    
    setIsModified(false); // Reset the modified state after clearing
  };

  // Function to delete the selected address
  const handleDeleteAddress = async () => {
    if (selectedAddress) {
      try {
        // Call the deleteShippingAddress function with the id of the selected address
        const response = await deleteShippingAddress({ id: selectedAddress.id });
  
        // Update the address book with the new data returned from the server
        if (response?.addressBook) {
          setSelectedAddress(null); // Clear the selection after deletion
          form.reset({
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            province: "ON",
            postalCode: "",
            country: "CA",
            tel: "",
            notes: "",
          });
          setAddressBook(response.addressBook); // Update the address book in the local state
        }
      } catch (error) {
        console.error("Failed to delete the address:", error);
        // Optionally, handle the error (e.g., show a notification)
      }
    }
  };

  const handleNewAddress = async () => {
    if (state.user?.id) {
      try {
        // Log before getting form data
        console.log("Getting form data...");
  
        // Get the data from the form
        const formData = form.getValues();
        console.log("Form data:", formData);
  
        // Prepare the payload for the newShippingAddress query
        const payload = {
          userID: state.user.id,  // userID from state
          data: {
            address: formData.address,
            city: formData.city,
            state: formData.province,
            zipcode: formData.postalCode,
            country: formData.country,
            tel: formData.tel,
            forename: formData.firstName,
            surname: formData.lastName,
          }
        };
  
        console.log("Sending payload to newShippingAddress:", payload);
  
        // Call the newShippingAddress function with the payload
        const response = await newShippingAddress(payload);
  
        // Log the response
        console.log("Response from newShippingAddress:", response);
  
        // Update the address book with the new data returned from the server
        if (response?.addressBook) {
          console.log("Updating address book with new data...");
  
          form.reset({
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            province: "ON",
            postalCode: "",
            country: "CA",
            tel: "",
            notes: "",
          });
          
          setAddressBook(response.addressBook); // Update the address book in the local state
  
          setIsModified(false); // Disable the "New" button after submission
  
          console.log("Address book updated and form reset.");
        }
      } catch (error) {
        console.error("Failed to add the new address:", error);
        // Optionally, handle the error (e.g., show a notification)
      }
    } else {
      console.log("No user ID found in state.");
    }
  };
  
  return (
    <div className="container mx-auto my-44">
      <h1 className="text-center font-black text-[#3D3D3D] text-3xl my-5">Confirm Order</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <div className="col-span-2 space-y-5">
              <OrderTable items={cartItems} />

              {/* Wrapper for ShippingForm and Buttons */}
              <div className="space-y-5">
                <ShippingForm control={control} watchcountry={watchcountry} setValue={form.setValue} disabled={storePickup} />

                {/* New and Clear Buttons */}
                <div className="flex gap-11 mt-4">
                  <Button
                    className="bg-[#184178] hover:bg-[#6A9EE6] h-12 rounded-2xl text-md text-white font-black w-[22%] shadow-md"
                    type="button"
                    disabled={!isModified || storePickup} // Disable New button if storePickup is true
                    onClick={handleNewAddress}
                  >
                    New
                  </Button>
                  <Button
                    className="border-2 border-[#184178] h-12 rounded-2xl text-md text-[#184178] font-black w-[22%] shadow-md bg-white"
                    type="button"
                    onClick={handleClear}
                    variant={"outline"}
                    disabled={storePickup} // Disable Clear button if storePickup is true
                  >
                    Clear
                  </Button>
                </div>

                {/* Dropdown Bar Below the ShippingForm */}
                <div className="items-center gap-3 space-y-4">
                  <h4 className="font-bold">Address Book</h4>
                  <Select
                  onValueChange={(value) => handleAddressSelect(addressBook.find(address => address.id === parseInt(value)))}
                  disabled={storePickup} // Disable the dropdown if storePickup is true
                >
                  <SelectTrigger className={`border-[#BEBEBE] h-14 w-[50%] ${storePickup ? 'opacity-50 cursor-not-allowed' : ''}`}>
                    <SelectValue placeholder="Default Address">
                      {selectedAddress ? `${selectedAddress.forename} ${selectedAddress.surname} - ${selectedAddress.address}, ${selectedAddress.city}` : "Default Address"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    {addressBook.map((address: any) => (
                      <SelectItem key={address.id} value={address.id.toString()} disabled={storePickup}>
                        {`${address.forename} ${address.surname} - ${address.address}, ${address.city}`}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                  {/* Alert Dialog for Deleting Address */}
                  {selectedAddress && !storePickup && ( // Hide Delete button when storePickup is true
                    <AlertDialog>
                      <AlertDialogTrigger>
                        <Button
                          className="border-2 border-[#184178] h-12 rounded-2xl text-md text-red-600 hover:text-red-800 font-black shadow-md"
                          type="button"
                          variant={"outline"}
                        >
                          Delete
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle className="text-center">Warning</AlertDialogTitle>
                          <AlertDialogDescription>
                            Are you sure you want to delete the selected address?
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction onClick={handleDeleteAddress}>Delete</AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  )}
                </div>
              </div>
            </div>

            <div className="col-span-1">
              <OrderSummary
                control={control}
                subtotal={subtotal}
                shipping={shipping}
                tax={tax}
                total={total}
              />
              <div className="mt-16 flex justify-center flex-col items-center gap-3">
                <Button
                  className="bg-[#184178] h-12 hover:bg-[#6A9EE6] rounded-2xl text-md text-white font-black w-4/5 shadow-md"
                  type="submit"
                >
                  {data?.oversize && !storePickup ? "Get a Quote" : "Confirm Order"}
                </Button>

                <Button
                  className="border-2 border-[#184178] h-12 rounded-2xl text-md text-[#184178] font-black w-4/5 shadow-md"
                  onClick={() => navigate("/cart")}
                  type="button"
                  variant={"outline"}
                >
                  Edit Order
                </Button>
              </div>
              {data?.oversize && !storePickup && (
                <div className="mx-4 mt-4 p-4 border border-yellow-500 bg-yellow-100 text-yellow-700 rounded-md">
                  <p>
                    Due to the size of item(s) you ordered, we need to manually quote you the best possible price.
                    Please click “Get a Quote” and we will quote you the best shipping option.
                  </p>
                </div>
              )}
              <div className="mt-6 text-center border shadow-md shadow-gray-400 py-4 mx-4 h-[11.7rem]">
                <h3 className="text-gray-700 font-bold">Payment methods</h3>
                <img src={PaymentOption} alt="Payment options: paypal, mastercard, visa" className=" object-contain mx-auto h-[9rem]" />
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default ConfirmOrderPage;