import SectionDivider from "@/components/sectionDivider";
import { FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { format } from "date-fns";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Control } from "react-hook-form";
import { CustomerInfoType } from "../types/Schema";

interface PersonalInformationProps {
  control: Control<CustomerInfoType>;
}

const PersonalInformation = ({ control }: PersonalInformationProps) => {
  return (
    <div className="space-y-4 pt-4">
      <div className="flex justify-center">
        <SectionDivider title="Personal Information" />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <FormField
          name="firstName"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="First Name" {...field} className="border-[#BEBEBE] h-14 rounded-lg" />
              </FormControl>
              <FormMessage className="mt-1" />
            </FormItem>
          )}
        />
        <FormField
          name="lastName"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="Last Name" {...field} className="border-[#BEBEBE] h-14 rounded-lg" />
              </FormControl>
              <FormMessage className="mt-1" />
            </FormItem>
          )}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 pb-4">
        <FormField
          name="gender"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger className="border-[#BEBEBE] h-14 rounded-lg">
                    <SelectValue placeholder="Gender" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Male">Male</SelectItem>
                    <SelectItem value="Female">Female</SelectItem>
                    <SelectItem value="Other">Other</SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage className="mt-1" />
            </FormItem>
          )}
        />
        <FormField
          name="dob"
          control={control}
          render={({ field }) => (
            <FormItem className="flex w-full">
              <Input
                placeholder="Date of Birth"
                {...field}
                value={field.value ? format(field.value, "yyyy-MM-dd") : ""}
                className="border-[#BEBEBE] h-14 rounded-lg"
                type="date"
              />
              <FormMessage className="mt-1" />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default PersonalInformation;
