import { Control, UseFormSetValue } from "react-hook-form";
import { ProfileType } from "../types/Schema";
import SectionDivider from "@/components/sectionDivider";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { getProvincesAndStates } from "@/utils/helpers/getProvincesAndStates";
import { formatPhoneNumber, isCanadianPhoneNumber } from "@/utils/helpers/formatPhoneNumber";

interface BillingInformationProps {
  control: Control<ProfileType>;
  watchSameAsShipping: boolean;
  watchbillingCountry: "CA" | "USA";
  setValue: UseFormSetValue<ProfileType>;
}

const BillingInformation = ({
  control,
  watchSameAsShipping,
  watchbillingCountry,
  setValue,
}: BillingInformationProps) => {
  const regions = getProvincesAndStates();
  return (
    <div className="space-y-4 pt-2">
      <div className="flex justify-center">
        <SectionDivider title="Billing Information" />
      </div>
      <div className="flex justify-center">
        <FormField
          name="sameAsShipping"
          control={control}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-2 space-y-0 my-2 text-[#7E7E7E]">
              <FormControl>
                <Checkbox checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>Same as Shipping Information</FormLabel>
              </div>
            </FormItem>
          )}
        />
      </div>
      {!watchSameAsShipping && (
        <>
          <FormField
            name="billingAddress"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="*Address" {...field} className="border-[#BEBEBE] h-14 rounded-lg" />
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
          <div className="grid grid-cols-2 gap-3">
            <FormField
              name="billingCity"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input placeholder="*City" {...field} className="border-[#BEBEBE] h-14 rounded-lg" />
                  </FormControl>
                  <FormMessage className="mt-1" />
                </FormItem>
              )}
            />
            <FormField
              name="billingPostalCode"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input placeholder="*Postal Code" {...field} className="border-[#BEBEBE] h-14 rounded-lg" />
                  </FormControl>
                  <FormMessage className="mt-1" />
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-2 gap-3">
            <FormField
              name="billingCountry"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Select
                      onValueChange={(value) => {
                        field.onChange(value);
                        setValue("province", "");
                      }}
                      value={field.value}
                    >
                      <SelectTrigger className="border-[#BEBEBE] h-14 rounded-lg">
                        <SelectValue placeholder="*Country" className="text-[#BEBEBE] h-14 rounded-lg" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="CA">Canada</SelectItem>
                        <SelectItem value="USA">USA</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="mt-1" />
                </FormItem>
              )}
            />
            <FormField
              name="billingProvince"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger className="border-[#BEBEBE] h-14 rounded-lg">
                        <SelectValue placeholder="*Province" className="text-[#BEBEBE] h-14 rounded-lg" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {(watchbillingCountry === "CA" ? regions.canada : regions.usa).map((region) => (
                            <SelectItem key={region.value} value={region.value}>
                              {region.label}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="mt-1" />
                </FormItem>
              )}
            />
          </div>
          <FormField
            name="billingTel"
            control={control}
            rules={{ validate: (value) => isCanadianPhoneNumber(value || "") }}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder="*Tel"
                    {...field}
                    className="border-[#BEBEBE] h-14 rounded-lg"
                    type="tel"
                    onBlur={(e) => {
                      field.onBlur();
                      e.currentTarget.value = formatPhoneNumber(e.currentTarget.value);
                    }}
                  />
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
          <FormField
            name="billingMobile"
            control={control}
            rules={{ validate: (value) => isCanadianPhoneNumber(value || "") }}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder="Mobile"
                    {...field}
                    className="border-[#BEBEBE] h-14 rounded-lg"
                    type="tel"
                    onBlur={(e) => {
                      field.onBlur();
                      e.currentTarget.value = formatPhoneNumber(e.currentTarget.value);
                    }}
                  />
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
        </>
      )}
      <div className="h-4"></div>
    </div>
  );
};

export default BillingInformation;
