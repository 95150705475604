import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { ContactUsSchema } from "../types/Schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { CustomInput } from "@/components/ui/customInput";
import { Mail } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useMutation } from "react-query";
import { contactUs } from "@/lib/queries";
import { toast } from 'react-toastify';

const ContactUsForm = () => {
  const form = useForm<ContactUsSchema>({
    resolver: zodResolver(ContactUsSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      subject: "",
      message: "",
    },
  });

  const mutation = useMutation(contactUs, {
    onSuccess(data) {
      if (data.status) {
        form.reset();
        toast.success("Thank you for your email, GLA will contact you as soon as possible.");
      }
    },
  });

  const onSubmit = async (data: ContactUsSchema) => {
    mutation.mutate({
      email: data.email,
      message: data.message,
      name: `${data.firstName} ${data.lastName}`,
      subject: data.subject,
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex flex-row justify-between items-center mt-20 mb-5 w-full">
          <FormField
            name="subject"
            control={form.control}
            render={({ field }) => (
              <FormItem className="w-28 md:ml-[51%]">
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger className="w-full md:w-[120px] border-black h-8">
                      <SelectValue placeholder="Subject" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Parts Inquiry">Parts Inquiry</SelectItem>
                      <SelectItem value="Shipping Inquiry">Shipping Inquiry</SelectItem>
                      <SelectItem value="Sales Inquiry">Sales Inquiry</SelectItem>
                      <SelectItem value="Other Inquiry">Other Inquiry</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage className="mt-1" />
              </FormItem>
            )}
          />
          <Button type="submit" className="bg-[#FFE512] h-8 w-28 md:w-28 hover:bg-[#FFE512] text-black font-bold">
            Send
          </Button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="space-y-3">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField
                name="firstName"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input {...field} placeholder="First Name" />
                    </FormControl>
                    <FormMessage className="mt-1" />
                  </FormItem>
                )}
              />
              <FormField
                name="lastName"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input {...field} placeholder="Last Name" />
                    </FormControl>
                    <FormMessage className="mt-1" />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              name="email"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <CustomInput startIcon={Mail} {...field} placeholder="Email" />
                  </FormControl>
                  <FormMessage className="mt-1" />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-3">
            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea placeholder="Message" {...field} rows={6} />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
      </form>
    </Form>
  );
};

export default ContactUsForm;
