/**
 * Returns an array of years starting from the specified start year up to the current year.
 * @param startYear The year to start the list from.
 * @returns An array of numbers representing the years.
 */
export function getYearList(startYear: number): number[] {
  const currentYear = new Date().getFullYear();
  const years: number[] = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
}
